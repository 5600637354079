import { Box, Button, DialogContent, DialogTitle, Input, Modal, ModalClose, ModalDialog, ModalDialogProps, Option, Select, Typography } from "@mui/joy";
import { FC, useState } from "react";
import { HourType, ShiftType, Timesheet } from "../../types";
import {
    calculateHours,
    calculateHours24, get24HourTime,
    hour_types,
    Hours,
    Minutes
} from "../../utils/helpers";
import Shifts from "./Shifts";
import { useAuth } from "../../providers/AuthContext";
import toast from "react-hot-toast";


interface ShiftProps {
    date: Date;
    timesheet: Timesheet;
    setTimesheet: React.Dispatch<React.SetStateAction<Timesheet>>
}
const DateShifts: FC<ShiftProps> = function ({ date, timesheet, setTimesheet }) {
    const [shiftTimes, setShiftTimes] = useState<Array<string>>([]);
    const [members, setMembers] = useState(['Select Member', 'Xanthes'])
    const [newTime, setNewTime] = useState({
        start_time: '',
        end_time: ''
    })
    const auth = useAuth();

    const [shift, setShift] = useState<ShiftType>({
        start_time: Hours[0] + ":" + Minutes[0] + ":" + "AM",
        end_time: Hours[0] + ":" + Minutes[1] + ":" + "AM",
        total_hours: 0,
        hour_type: 'Regular Hours',
        member: ''
    })

    function defaultShift() {
        setShift({
            start_time: Hours[0] + ":" + Minutes[0] + ":" + "AM",
            end_time: Hours[0] + ":" + Minutes[1] + ":" + "AM",
            total_hours: 0,
            hour_type: 'Regular Hours',
            member: ''
        })
    }
    function handleAdd() {
        // setShiftTimes((prev) => [...prev, newTime]);
        // setNewTime('')
        setVariant("outlined");
        
    }
    const [variant, setVariant] = useState<
        ModalDialogProps['variant'] | undefined
    >(undefined);
    
    return (
        <div className="date-shifts">
            <div>
                <Typography variant="soft" width={150} p={1}>{date.toDateString()}</Typography>

            </div>
            <div className="">
                {timesheet.days.map((value, idx) => {
                    if (value.day === date.toDateString()) {
                        return <Shifts date={value.day} timesheet={timesheet} setTimesheet={setTimesheet} shifts={value.shifts} />
                    }
                    return <></>
                })}

                {/* {shiftTimes.map((time, i) => {
                    return <Input type="time" size={"sm"} sx={{ width: 'fit-content' }} value={time} onChange={(e) => {
                        setShiftTimes((prev) => {
                            // prev[i] = e.target.value;
                            const arr = [...prev]
                            arr[i] = e.target.value
                            return arr;
                        })
                    }}></Input>
                })} */}
            </div>
            <div>
                <Button onClick={handleAdd}>Add Shifts</Button>
            </div>
            <Modal open={!!variant} onClose={() => setVariant(undefined)} sx={{
                overflowX: 'scroll'
            }}>
                <ModalDialog variant={variant}>
                    <ModalClose />
                    <DialogTitle>Add Time shift</DialogTitle>
                    <DialogContent>
                        <div className="add-time-shift">
                            <div className="">

                                <div className="shift-inputs">
                                    <Typography>Start Time</Typography>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                        <Select value={shift.start_time.split(":")[0]} onChange={(_, value) => {
                                            if (value) {
                                                setShift((prev) => {
                                                    const time = prev.start_time.split(":");
                                                    return {
                                                        ...prev,
                                                        start_time: value + ":" + time[1] + ":" + time[2]
                                                    }
                                                })
                                            }
                                        }}>
                                            {Hours.map((hour) => {
                                                return <Option value={hour}>{hour}</Option>
                                            })}
                                        </Select>

                                        <Select value={shift.start_time.split(":")[1]} onChange={(_, value) => {
                                            if (value) {
                                                setShift((prev) => {
                                                    const time = prev.start_time.split(":");
                                                    return {
                                                        ...prev,
                                                        start_time: time[0] + ":" + value + ":" + time[2]
                                                    }
                                                })
                                            }
                                        }}>
                                            {Minutes.map((minute) => {
                                                return <Option value={minute}>{minute}</Option>
                                            })}
                                        </Select>

                                        <Select value={shift.start_time.split(":")[2]} onChange={(_, value) => {
                                            if (value) {
                                                setShift((prev) => {
                                                    const time = prev.start_time.split(":");
                                                    return {
                                                        ...prev,
                                                        start_time: time[0] + ":" + time[1] + ":" + value
                                                    }
                                                })
                                            }
                                        }}>
                                            <Option value={"AM"}>AM</Option>
                                            <Option value={"PM"}>PM</Option>
                                        </Select>
                                    </div>
                                    {/*<Input type="time" size={"sm"} sx={{ width: 'fit-content' }} value={shift.start_time} onChange={(e) => {*/}
                                    {/*  */}
                                    {/*    // toast(e.target.value)*/}
                                    {/*    setShift((prev) => {*/}
                                    {/*        return {*/}
                                    {/*            ...prev,*/}
                                    {/*            start_time: e.target.value*/}
                                    {/*        }*/}
                                    {/*    })*/}
                                    {/*}}></Input>*/}
                                </div>
                                <div className="shift-inputs">
                                    <Typography>End Time</Typography>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                        <Select value={shift.end_time.split(":")[0]} onChange={(_, value) => {
                                            if (value) {
                                                setShift((prev) => {
                                                    const time = prev.end_time.split(":");
                                                    return {
                                                        ...prev,
                                                        end_time: value + ":" + time[1] + ":" + time[2]
                                                    }
                                                })
                                            }
                                        }}>
                                            {Hours.map((hour) => {
                                                return <Option value={hour}>{hour}</Option>
                                            })}
                                        </Select>

                                        <Select value={shift.end_time.split(":")[1]} onChange={(_, value) => {
                                            if (value) {
                                                setShift((prev) => {
                                                    const time = prev.end_time.split(":");
                                                    return {
                                                        ...prev,
                                                        end_time: time[0] + ":" + value + ":" + time[2]
                                                    }
                                                })
                                            }
                                        }}>
                                            {Minutes.map((minute) => {
                                                return <Option value={minute}>{minute}</Option>
                                            })}
                                        </Select>

                                        <Select value={shift.end_time.split(":")[2]} onChange={(_, value) => {
                                            if (value) {
                                                setShift((prev) => {
                                                    const time = prev.end_time.split(":");
                                                    return {
                                                        ...prev,
                                                        end_time: time[0] + ":" + time[1] + ":" + value
                                                    }
                                                })
                                            }
                                        }}>
                                            <Option value={"AM"}>AM</Option>
                                            <Option value={"PM"}>PM</Option>
                                        </Select>
                                    </div>
                                    {/*<Input type="time" size={"sm"} sx={{ width: 'fit-content' }} value={shift.end_time} onChange={(e) => {*/}
                                    {/*    //   toast(e.target.value)*/}
                                    {/*      if (e.target.value.split(":")[0] == "00" &&e.target.value.split(":")[1] == "00") {*/}
                                    {/*        toast(e.target.value)*/}
                                    {/*        setShift((prev) => {*/}
                                    {/*            return {*/}
                                    {/*                ...prev,*/}
                                    {/*                end_time: "23:59"*/}
                                    {/*            }*/}
                                    {/*        })*/}
                                    {/*        return;*/}
                                    {/*    } */}
                                    {/*    setShift((prev) => {*/}
                                    {/*        return {*/}
                                    {/*            ...prev,*/}
                                    {/*            end_time: e.target.value*/}
                                    {/*        }*/}
                                    {/*    })*/}
                                    {/*}}></Input>*/}
                                </div>

                                <div className="shift-inputs">
                                    <Typography>Hours worked</Typography>
                                    <Input type="text" size={"sm"} sx={{ width: 'fit-content', fontWeight: 800 }} value={calculateHours24(shift.start_time, shift.end_time)} onChange={(e) => {
                                    
                                    }} readOnly={true} ></Input>
                                </div>
                                <div className="shift-inputs">
                                    <Typography>Work Type</Typography>
                                    <Select value={shift.hour_type} onChange={(e, value) => {

                                        if (value) {
                                            //@ts-ignore
                                            setShift((prev) => {
                                                return {
                                                    ...prev,
                                                    hour_type: value
                                                }
                                            })
                                        }

                                    }} variant="soft" sx={{
                                        width: 200
                                    }}>
                                        {hour_types.map((hour, idx) => {
                                            return <Option value={hour} key={idx}>{hour}</Option>
                                        })}

                                    </Select>
                                </div>
                                <div className="shift-inputs">

                                    <Typography>Member</Typography>

                                    <datalist id='suggestions'>
                                        {auth.members.filter((member) => {
                                            const full_name = member.first_name + " " + member.last_name;
                                            if (shift.member == '') return false;
                                            return full_name.toLowerCase().includes(shift.member.toLowerCase())
                                        }).map((member) => {
                                            return <option value={member.first_name + " " + member.last_name}>{member.first_name + " " + member.last_name}</option>
                                        })}
                                    </datalist>
                                    <input list="suggestions" onChange={(e) => {
                                        setShift((prev) => {
                                            return {
                                                ...prev,
                                                member: e.target.value
                                            }
                                        })
                                    }} value={shift.member}></input>

                                </div>

                            </div>
                            <Button variant="solid" color="primary" size="md" sx={{
                                width: 'fit-content'

                            }} onClick={() => {

                                // alert(get24HourTime(shift.start_time))
                                // alert(shift.end_time)
                                // alert(calculateHours24(shift.start_time, shift.end_time))
                                // return;
                                try {
                                    const s = shift.start_time.split(":");
                                    const e = shift.end_time.split(":")
                                    // if (s.length !== 2 || e.length !== 2) {
                                    //     toast.error("Enter valid start and end time")
                                    //     return;
                                    // }
                                    //@ts-ignore
                                    if (shift.hour_type === 'Select hour type') {
                                        toast.error("Select valid hour type");
                                        return;
                                    }
                                    const total_hours = calculateHours24(shift.start_time, shift.end_time);
                                    if (total_hours <= 0) {
                                        toast.error("Negative or zero hours not allowed")
                                        return  
                                    }
                                    if (shift.member) {
                                        // toast.error(shift.member)
                                        const members = auth.members.filter((member) => {
                                            return member.first_name + " " + member.last_name == shift.member
                                        });
                                        // alert(JSON.stringify(members))
                                        if (members.length === 0) {
                                            toast.error("Enter valid member");
                                            return;
                                        }
                                    }
                                    else {
                                        toast.error('enter valid shift member');
                                        return;
                                    }
                                } catch (error) {
                                    toast.error(JSON.stringify(error));
                                    return;
                                }

                                setTimesheet((prev) => {
                                    // alert(date)
                                    let days = prev.days
                                    const day = days.filter((value) => value.day == date.toDateString());
                                    // alert(JSON.stringify(date));
                                    if (day.length === 0) {
                                        days.push({ day: date.toDateString(), shifts: [{...shift, total_hours: calculateHours24(shift.start_time, shift.end_time)}] })
                                    }
                                    else {
                                        day[0].shifts = [...day[0].shifts, {...shift, total_hours: calculateHours24(shift.start_time, shift.end_time)}];
                                    }
                                    return {
                                        ...prev,
                                        days: days
                                    }
                                })
                                defaultShift()
                                setVariant(undefined)
                                
                            }}>Add Shift</Button>
                        </div>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </div>

    )
}


export default DateShifts;