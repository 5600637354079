import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Table from '@mui/joy/Table';
import { Days, ShiftType, Timesheet } from '../../types';
import { Button, Input, Sheet } from '@mui/joy';
import {
    Hours,
    Minutes,
    calculateHours,
    hour_types,
    calculateHours24
} from '../../utils/helpers';
import { useAuth } from '../../providers/AuthContext';
import toast from 'react-hot-toast';

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

interface ShiftProps {
    date: string;
    timesheet: Timesheet;
    shifts: Array<ShiftType>
    setTimesheet: React.Dispatch<React.SetStateAction<Timesheet>>
}

const Shifts: React.FC<ShiftProps> = function ({ date, timesheet, setTimesheet, shifts }) {
    const [color, setColor] = React.useState<ColorPaletteProp>('neutral');

    const auth = useAuth();

    const [phase, setPhase] = React.useState("AM")
    const [approveLoading, setApproveLoading] = React.useState(false);
    const handleApprove = async function(approve: boolean, id: string) {
        try {
            // alert(timesheet.approved);
            setApproveLoading(true);
            //@ts-ignore
            const data = await fetch(`${process.env.REACT_APP_server}/timesheets/approval/${timesheet._id}?approve=${timesheet.approved ? 'false' : 'true'}`, {
                method: 'PUT',
                headers: {
                    'Content-type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({
                    approver: auth.user?.first_name + " " + auth.user?.last_name,
                    approve,
                    id: id
                })
            });
            const res = await data.json();
            if (res.success) {
                toast.success("Status Updated");
                setInterval(() => {
                    window.location.reload();
                }, 1200)
            }
            else {
                toast.error(res.error);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setApproveLoading(false)
        }
        //@ts-ignore
        
    }
    return (
        <div style={{
            overflowX: 'auto',
            width: '95dvw'
        }}>
            {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1,
                    mb: 2,
                    ml: 1
                }}
            >
            </Box> */}

            <Table aria-label="table 'outlined's" variant={'outlined'} sx={{
                overflowX: 'auto',
                width: '95dvw',


            }} >
                <thead >
                    <tr style={{ backgroundColor: 'black' }}>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Start Time</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>End Time</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Total hours</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Hour Type</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Member</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Actions</th>
                    </tr>

                </thead>
                <tbody>
                    {shifts.map((shift, idx) => {
                        return <tr style={{ backgroundColor: shift.approved ? '#B8F4B8' : ''}}>

                            <td>
                                {/*{"12:15:pm".split(":")[2]}*/}
                                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                <Select defaultValue={Hours[0]} name='hours' value={shift.start_time.split(":")[0]} onChange={(e, value) => {
                                    if (!auth.user?.role) {
                                        window.location.reload()
                                    }
                                    if (!value) return;
                                    if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                        // toast.error(auth.user.role)
                                        toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                        return;
                                    }
                                    setTimesheet((prevs) => {
                                        let days = [...prevs.days];
                                        let newDays = days.map((day, dayIndex) => {
                                            if (day.day === date) {
                                                let newShifts: Array<ShiftType> = []

                                                for (const items of shifts) {
                                                    const obj = { ...items };
                                                    newShifts.push(obj);
                                                }
                                                const timeArray = newShifts[idx].start_time.split(":")
                                                newShifts[idx].start_time = value + ":" + timeArray[1] + ":" + timeArray[2]
                                                newShifts[idx].total_hours = calculateHours24(newShifts[idx].start_time, newShifts[idx].end_time)
                                                day.shifts = newShifts
                                                // day.shifts[idx].start_time = e.target.value;
                                                return day;
                                            }
                                            return day;
                                        })
                                        return {
                                            ...prevs,
                                            days: newDays
                                        }
                                    })
                                }}>
                                    {Hours.map((hour) => {
                                        return <Option value={hour}>{hour}</Option>
                                    })}
                                </Select>
                                <Select defaultValue={Minutes[0]} name='minutes'  value={shift.start_time.split(":")[1]} onChange={(e, value) => {
                                    if (!auth.user?.role) {
                                        window.location.reload()
                                    }
                                    if (!value) return;
                                    if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                        // toast.error(auth.user.role)
                                        toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                        return;
                                    }
                                    setTimesheet((prevs) => {
                                        let days = [...prevs.days];
                                        let newDays = days.map((day, dayIndex) => {
                                            if (day.day === date) {
                                                let newShifts: Array<ShiftType> = []

                                                for (const items of shifts) {
                                                    const obj = { ...items };
                                                    newShifts.push(obj);
                                                }
                                                const timeArray = newShifts[idx].start_time.split(":")
                                                newShifts[idx].start_time =  timeArray[0] + ":" + value + ":" + timeArray[2]
                                                newShifts[idx].total_hours = calculateHours24(newShifts[idx].start_time, newShifts[idx].end_time)

                                                day.shifts = newShifts
                                                // day.shifts[idx].start_time = e.target.value;
                                                return day;
                                            }
                                            return day;
                                        })
                                        return {
                                            ...prevs,
                                            days: newDays
                                        }
                                    })
                                }}>
                                    {Minutes.map((minute) => {
                                        return <Option value={minute}>{minute}</Option>
                                    })}
                                </Select>

                                <Select value={shift.start_time.split(":")[2]} name='phase' onChange={(e, value) => {
                                    if (!auth.user?.role) {
                                        window.location.reload()
                                    }
                                    if (!value) return;
                                    if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                        // toast.error(auth.user.role)
                                        toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                        return;
                                    }
                                    setTimesheet((prevs) => {
                                        let days = [...prevs.days];
                                        let newDays = days.map((day, dayIndex) => {
                                            if (day.day === date) {
                                                let newShifts: Array<ShiftType> = []

                                                for (const items of shifts) {
                                                    const obj = { ...items };
                                                    newShifts.push(obj);
                                                }
                                                const timeArray = newShifts[idx].start_time.split(":")
                                                newShifts[idx].start_time =  timeArray[0] + ":" + timeArray[1] + ":" + value
                                                newShifts[idx].total_hours = calculateHours24(newShifts[idx].start_time, newShifts[idx].end_time)

                                                day.shifts = newShifts
                                                // day.shifts[idx].start_time = e.target.value;
                                                return day;
                                            }
                                            return day;
                                        })
                                        return {
                                            ...prevs,
                                            days: newDays
                                        }
                                    })
                                }}>
                                    <Option value={"AM"}>AM</Option>
                                    <Option value={"PM"}>PM</Option>
                                </Select>
                                </div>
                                {/*<Input type="time" size={"sm"} sx={{ width: 'fit-content' }} value={shift.start_time} onChange={(e) => {*/}
                                {/*    if (!auth.user?.role) {*/}
                                {/*        window.location.reload()*/}
                                {/*    }*/}
                                {/*    if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {*/}
                                {/*        // toast.error(auth.user.role)*/}
                                {/*        toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");*/}
                                {/*        return;*/}
                                {/*    }*/}
                                {/*    setTimesheet((prevs) => {*/}
                                {/*        let days = [...prevs.days];*/}
                                {/*        let newDays = days.map((day, dayIndex) => {*/}
                                {/*            if (day.day === date) {*/}
                                {/*                let newShifts: Array<ShiftType> = []*/}

                                {/*                for (const items of shifts) {*/}
                                {/*                    const obj = { ...items };*/}
                                {/*                    newShifts.push(obj);*/}
                                {/*                }*/}
                                {/*                newShifts[idx].start_time = e.target.value;*/}
                                {/*                newShifts[idx].total_hours = calculateHours(newShifts[idx].start_time, newShifts[idx].end_time)*/}
                                {/*                day.shifts = newShifts*/}
                                {/*                // day.shifts[idx].start_time = e.target.value;*/}
                                {/*                return day;*/}
                                {/*            }*/}
                                {/*            return day;*/}
                                {/*        })*/}
                                {/*        return {*/}
                                {/*            ...prevs,*/}
                                {/*            days: newDays*/}
                                {/*        }*/}
                                {/*    })*/}
                                {/*}}></Input>*/}
                            </td>
                            <td>
                                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                    <Select defaultValue={Hours[0]} name='hours' value={shift.end_time.split(":")[0]} onChange={(e, value) => {
                                        if (!auth.user?.role) {
                                            window.location.reload()
                                        }
                                        if (!value) return;
                                        if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                            // toast.error(auth.user.role)
                                            toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                            return;
                                        }
                                        setTimesheet((prevs) => {
                                            let days = [...prevs.days];
                                            let newDays = days.map((day, dayIndex) => {
                                                if (day.day === date) {
                                                    let newShifts: Array<ShiftType> = []

                                                    for (const items of shifts) {
                                                        const obj = { ...items };
                                                        newShifts.push(obj);
                                                    }
                                                    const timeArray = newShifts[idx].end_time.split(":")
                                                    newShifts[idx].end_time = value + ":" + timeArray[1] + ":" + timeArray[2]
                                                    newShifts[idx].total_hours = calculateHours24(newShifts[idx].start_time, newShifts[idx].end_time)
                                                    day.shifts = newShifts
                                                    // day.shifts[idx].start_time = e.target.value;
                                                    return day;
                                                }
                                                return day;
                                            })
                                            return {
                                                ...prevs,
                                                days: newDays
                                            }
                                        })
                                    }}>
                                        {Hours.map((hour) => {
                                            return <Option value={hour}>{hour}</Option>
                                        })}
                                    </Select>
                                    <Select defaultValue={Minutes[0]} name='minutes'  value={shift.end_time.split(":")[1]} onChange={(e, value) => {
                                        if (!auth.user?.role) {
                                            window.location.reload()
                                        }
                                        if (!value) return;
                                        if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                            // toast.error(auth.user.role)
                                            toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                            return;
                                        }
                                        setTimesheet((prevs) => {
                                            let days = [...prevs.days];
                                            let newDays = days.map((day, dayIndex) => {
                                                if (day.day === date) {
                                                    let newShifts: Array<ShiftType> = []

                                                    for (const items of shifts) {
                                                        const obj = { ...items };
                                                        newShifts.push(obj);
                                                    }
                                                    const timeArray = newShifts[idx].end_time.split(":")
                                                    newShifts[idx].end_time =  timeArray[0] + ":" + value + ":" + timeArray[2]
                                                    newShifts[idx].total_hours = calculateHours24(newShifts[idx].start_time, newShifts[idx].end_time)

                                                    day.shifts = newShifts
                                                    // day.shifts[idx].start_time = e.target.value;
                                                    return day;
                                                }
                                                return day;
                                            })
                                            return {
                                                ...prevs,
                                                days: newDays
                                            }
                                        })
                                    }}>
                                        {Minutes.map((minute) => {
                                            return <Option value={minute}>{minute}</Option>
                                        })}
                                    </Select>

                                    <Select value={shift.end_time.split(":")[2]} name='phase' onChange={(e, value) => {
                                        if (!auth.user?.role) {
                                            window.location.reload()
                                        }
                                        if (!value) return;
                                        if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                            // toast.error(auth.user.role)
                                            toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                            return;
                                        }
                                        setTimesheet((prevs) => {
                                            let days = [...prevs.days];
                                            let newDays = days.map((day, dayIndex) => {
                                                if (day.day === date) {
                                                    let newShifts: Array<ShiftType> = []

                                                    for (const items of shifts) {
                                                        const obj = { ...items };
                                                        newShifts.push(obj);
                                                    }
                                                    const timeArray = newShifts[idx].end_time.split(":")
                                                    newShifts[idx].end_time =  timeArray[0] + ":" + timeArray[1] + ":" + value
                                                    newShifts[idx].total_hours = calculateHours24(newShifts[idx].start_time, newShifts[idx].end_time)

                                                    day.shifts = newShifts
                                                    // day.shifts[idx].start_time = e.target.value;
                                                    return day;
                                                }
                                                return day;
                                            })
                                            return {
                                                ...prevs,
                                                days: newDays
                                            }
                                        })
                                    }}>
                                        <Option value={"AM"}>AM</Option>
                                        <Option value={"PM"}>PM</Option>
                                    </Select>
                                </div>
                                {/*<Input type="time" size={"sm"} sx={{ width: 'fit-content' }} value={shift.end_time} onChange={(e) => {*/}
                                {/*    if (!auth.user?.role) {*/}
                                {/*        window.location.reload()*/}
                                {/*    }*/}
                                {/*    if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {*/}
                                {/*        // toast.error(auth.user.role)*/}
                                {/*        toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");*/}
                                {/*        return;*/}
                                {/*    }*/}
                                {/*    if (e.target.value.split(":")[0] == "00" && e.target.value.split(":")[1] == "00") {*/}
                                {/*        // toast(e.target.value)*/}
                                {/*        setTimesheet((prevs) => {*/}


                                {/*            let newDays = prevs.days.map((day, dayIndex) => {*/}
                                {/*                if (day.day === date) {*/}
                                {/*                    let newShifts: Array<ShiftType> = []*/}

                                {/*                    for (const items of shifts) {*/}
                                {/*                        const obj = { ...items };*/}
                                {/*                        newShifts.push(obj);*/}
                                {/*                    }*/}
                                {/*                    newShifts[idx].end_time = "23:59"*/}
                                {/*                    newShifts[idx].total_hours = calculateHours(newShifts[idx].start_time, newShifts[idx].end_time)*/}
                                {/*                    day.shifts = newShifts*/}
                                {/*                    // day.shifts[idx].start_time = e.target.value;*/}
                                {/*                    return day;*/}
                                {/*                }*/}
                                {/*                return day;*/}
                                {/*            })*/}
                                {/*            return {*/}
                                {/*                ...prevs,*/}
                                {/*                days: newDays*/}
                                {/*            }*/}
                                {/*        })*/}
                                {/*        return;*/}
                                {/*    }*/}
                                {/*    setTimesheet((prevs) => {*/}

                                {/*        let newDays = prevs.days.map((day, dayIndex) => {*/}
                                {/*            if (day.day === date) {*/}
                                {/*                let newShifts: Array<ShiftType> = []*/}

                                {/*                for (const items of shifts) {*/}
                                {/*                    const obj = { ...items };*/}
                                {/*                    newShifts.push(obj);*/}
                                {/*                }*/}
                                {/*                newShifts[idx].end_time = e.target.value;*/}
                                {/*                newShifts[idx].total_hours = calculateHours(newShifts[idx].start_time, newShifts[idx].end_time)*/}
                                {/*                day.shifts = newShifts*/}
                                {/*                // day.shifts[idx].start_time = e.target.value;*/}
                                {/*                return day;*/}
                                {/*            }*/}
                                {/*            return day;*/}
                                {/*        })*/}
                                {/*        return {*/}
                                {/*            ...prevs,*/}
                                {/*            days: newDays*/}
                                {/*        }*/}
                                {/*    })*/}
                                {/*}}></Input>*/}
                            </td>
                            <td>
                                <Input type="text" readOnly size={"sm"} sx={{ width: 'fit-content', fontWeight: 800 }} value={shift.total_hours} onChange={(e) => {

                                }}></Input>
                            </td>
                            <td>
                                <Select value={shift.hour_type} onChange={(e, value) => {
                                    if (!auth.user?.role) {
                                        window.location.reload()
                                    }
                                    if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                        // toast.error(auth.user.role)
                                        toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                        return;
                                    }
                                    if (value != null) {
                                        setTimesheet((prevs) => {
                                            let newDays = prevs.days.map((day, dayIndex) => {
                                                if (day.day === date) {
                                                    let newShifts: Array<ShiftType> = []

                                                    for (const items of shifts) {
                                                        const obj = { ...items };
                                                        newShifts.push(obj);
                                                    }
                                                    //@ts-ignore
                                                    newShifts[idx].hour_type = value
                                                    day.shifts = newShifts
                                                    // day.shifts[idx].start_time = e.target.value;
                                                    return day;
                                                }
                                                return day;
                                            })
                                            return {
                                                ...prevs,
                                                days: newDays
                                            }
                                        })
                                    }

                                }} variant="soft" sx={{
                                    width: 200
                                }}>
                                    {hour_types.map((hour, idx) => {
                                        return <Option value={hour} key={idx}>{hour}</Option>
                                    })}

                                </Select>
                            </td>
                            <td>
                                <Select value={shift.member} >
                                    <Option value={shift.member}>{shift.member}</Option>
                                </Select>
                            </td>
                            <td style={{
                               
                     
                            }}>
                               
                                    <Button variant='outlined' color='warning' onClick={() => {
                                        if (!auth.user?.role) {
                                            window.location.reload()
                                        }
                                        if (shift.approved && (auth.user?.role !== 'admin' && auth.user?.role !== 'coordinator')) {
                                            // toast.error(auth.user.role)
                                            toast.error("You can't change this entry as it is already approved by Coordinator/Payroll, Please contact Coordinator/Payroll in case of conflict!");
                                            return;
                                        }
                                        setTimesheet((prev) => {

                                            let newDays = prev.days.map((day, dayIndex) => {
                                                if (day.day === date) {
                                                    day.shifts.splice(idx, 1)
                                                    if (day.shifts.length === 0) return null;
                                                }
                                                return day;
                                            })
                                            let updated = newDays.filter(e => e !== null) as Days[]
                                            return {
                                                ...prev,
                                                days: updated
                                            }
                                        })
                                    }}>Delete</Button>

                                    {auth.user?.role === 'employee' ? <></> : <Button variant={'solid'} color={shift.approved ? 'danger' : 'success'} sx={{ marginLeft: 1 }} loading={approveLoading} onClick={async () => {
                            
                                        await handleApprove(shift.approved ? false : true, shift._id as any);
                                        
                                        setTimesheet((prevs) => {
                                            let newDays = prevs.days.map((day, dayIndex) => {
                                                if (day.day === date) {
                                                    let newShifts: Array<ShiftType> = []

                                                    for (const items of shifts) {
                                                        const obj = { ...items };
                                                        newShifts.push(obj);
                                                    }
                                                    newShifts[idx].approved = shift.approved ? false : true

                                                    if (auth.user) {
                                                        newShifts[idx].approver = auth.user.first_name + " " + auth.user.last_name
                                                    }
                                                    day.shifts = newShifts
                                                    // day.shifts[idx].start_time = e.target.value;
                                                    return day;
                                                }
                                                return day;
                                            })
                                            return {
                                                ...prevs,
                                                days: newDays
                                            }
                                        })
                                    }}>{shift.approved ? "Unapprove" : "Approve"}</Button>}
                                
                                {shift.approved && shift.approver && <p style={{overflow: 'hidden', cursor: 'pointer'}} onClick={() => {
                                    alert("Approved name : " + shift.approver)
                                }}>Approved by <span style={{fontWeight: 'bold'}}>{shift.approver.substring(0,20) + (shift.approver.length > 20 ? "...." : "")}</span></p>}
                            </td>

                        </tr>

                    })}
                    {/* {rows.map((row) => (
                        <tr key={row.name}>
                            <td>{row.name}</td>
                            <td>{row.calories}</td>
                            <td>{row.fat}</td>
                            <td>{row.carbs}</td>
                            <td>{row.protein}</td>
                        </tr>
                    ))} */}
                </tbody>
            </Table>

        </div>
    );
}
export default Shifts;