import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../../ui/Navbar';
import { Autocomplete, Button, CircularProgress, Input, Option, Select, Typography } from '@mui/joy';
import { Add, Label } from '@mui/icons-material';
import { useAuth } from '../../providers/AuthContext';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce'
import { Loader } from '../../ui/Loader';

interface SubmitMileageProps {
    // Define component props here
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octobor', 'November', 'December']

const SubmitMileage: FC<SubmitMileageProps> = () => {
    const auth = useAuth()
    const [mileage, setMileage] = useState({
        member: '',
        day: '',
        month: months[(new Date().getMonth() - 1) % 12],
        year: '',
        from: '',
        fromId: '',
        toId: '',
        to: '',
        km_auto: 0,
        km_manual: 0,
        comment: '',
        file: null as File | null,
        bridge: '',
        parking: ''
    })
    const [attachement, setAttachement] = useState<File>()
    const [distanceLoading, setDistanceLoading] = useState(false)
    const [fromLoading, setFromLoading] = useState(false)
    const [toLoading, setToLoading] = useState(false)
    const [fromSuggestions, setFromSuggestions] = useState<Array<{ place_id: string, description: string }>>([]);
    const [toSuggestions, setToSuggestions] = useState<Array<{ place_id: string, description: string }>>([])
    const fileRef = useRef<HTMLInputElement>(null)
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setMileage((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    useEffect(() => {

        async function calculateDistance() {
            setDistanceLoading(true)
            try {
                const response = await fetch(`${process.env.REACT_APP_server}/mileage/distance`, {
                    method: 'POST',
                    body: JSON.stringify({ from: mileage.fromId, to: mileage.toId }),
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const data = await response.json();

                setDistanceLoading(false)
                if (!data.error) {
                    setMileage((prev) => {
                        return {
                            ...prev,
                            km_auto: Number((data.distance / 1000).toFixed(1)),
                            km_manual: Number((data.distance / 1000).toFixed(1))
                        }
                    })
                }
            } catch (error) {
                setDistanceLoading(false)
            }

        }
        if (mileage.fromId.length !== 0 && mileage.toId.length !== 0) {

            calculateDistance()
        }
    }, [mileage.fromId, mileage.toId])
    const fetchFromOptions = async (value: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_server}/mileage/suggestion?keyword=${value}`, {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            const data = await response.json()
            setFromLoading(false)
            if (!data.error) {
                setFromSuggestions(data)
            }
        } catch (error) {
            setFromLoading(false)
        }
    }
    const fetchToOptions = async (value: string) => {
        const response = await fetch(`${process.env.REACT_APP_server}/mileage/suggestion?keyword=${value}`, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        const data = await response.json()
        setToLoading(false)
        if (!data.error) {
            setToSuggestions(data)
        }
    }
    console.log(fromSuggestions)

    const debouncedFetchFrom = useCallback(debounce(fetchFromOptions, 3000), [])
    const debouncedFetchTo = useCallback(debounce(fetchToOptions, 3000), [])

    async function handleSubmit() {
        if (!mileage.day) {
            toast.error("Day required!");
            return;
        }
        if (!mileage.month) {
            toast.error("Month required")
            return;
        }
        if (!mileage.year) {
            toast.error("year required")
            return;
        }
        if (!mileage.member) {
            toast.error("Member required")
            return;
        }
        if (!mileage.from || !mileage.to || !mileage.km_auto) {
            toast.error("From and to required!")
            return;
        }
        if (mileage.bridge == undefined || !mileage.bridge) {
            toast.error("Bridge charges required")
            return;
        }
        if (mileage.parking === undefined || !mileage.parking) {
            toast.error("Parking charges required")
            return;
        }
        if (!mileage.comment) {
            if (mileage.km_auto != mileage.km_manual) {
                toast.error("comment required if calculated kilometers are not equal to manual kilometers")
                return;
            }
        }
        try {
            const form = new FormData()
            form.append('day', mileage.day)
            form.append('month', mileage.month)
            form.append('year', mileage.year)
            form.append('from', mileage.from)
            form.append('to', mileage.to)
            form.append('km_auto', mileage.km_auto.toString())
            form.append('km_manual', mileage.km_manual.toString())
            form.append('bridge', mileage.bridge)
            form.append('parking', mileage.parking)
            form.append('comment', mileage.comment)
            form.append('member', mileage.member)
            form.append('file', attachement as File)
            const response = await fetch(`${process.env.REACT_APP_server}/mileage`, {
                method: 'POST',
                body: form,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            const data = await response.json();
            console.log(data);

            if (data.error) {
                toast.error(data.error)
                return;
            }
            if (data.success) {
                toast.success("Mileage entry added successfully!")
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }
            else {
                toast.error("Something went wrong")
            }
        } catch (error) {
            toast.error("Something went wrong")
        }

    }
    return (
        <div style={{ marginLeft: 25, marginRight: 25 }}>
            <Navbar />
            {distanceLoading && <div style={{

                display: 'flex',


                position: 'absolute',
                left: '50%',
            }}>

                <Loader />

            </div>}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                marginTop: 20,
                width: 'fit-content',
                maxWidth: '85%',
                marginLeft: 'auto',
                marginRight: 'auto',
                overflowX: 'scroll',
                opacity: distanceLoading ? 0.25 : 1,

            }} >

                <div>
                    <div style={{ display: 'flex', gap: 20, flexDirection: window.innerWidth < 600 ? 'column' : 'row' }}>
                        <div>
                            <Typography fontSize={'lg'} fontWeight={'bold'}>Date:</Typography>
                            <div style={{ display: 'flex', gap: 4 }}>
                                <Input type='number' name='day' value={mileage.day} placeholder='DD' onChange={handleChange}></Input>
                                <Select value={mileage.month} onChange={(e, v) => {
                                    if (v) {
                                        setMileage((prev) => {
                                            return {
                                                ...prev,
                                                month: v
                                            }
                                        })
                                    }

                                }}>
                                    {months.map((month) => <Option value={month} key={month}>{month}</Option>)}
                                </Select>
                                <Input type='number' name='year' value={mileage.year} placeholder='YYYY' onChange={handleChange}></Input>
                            </div>
                        </div>
                        <div>
                            <Typography fontSize={'lg'} fontWeight={'bold'}>Member:</Typography>
                            <div style={{ display: 'flex', gap: 4 }}>
                                <Autocomplete options={auth.members.map((user) => {
                                    return user.first_name + " " + user.last_name
                                })} disableClearable={true} placeholder='Member Name' id='member' name='member' onChange={(e, v) => [
                                    setMileage((prev) => {
                                        return {
                                            ...prev,
                                            member: v
                                        }
                                    })
                                ]} />
                            </div>
                        </div>

                    </div>

                </div>

                <div >
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Locations:</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: 5 }}>
                        <div>
                            <label htmlFor='from'>From:</label>
                            <Autocomplete loading={fromLoading} getOptionLabel={option => option.description} options={fromSuggestions} disableClearable={true} onInputChange={(e, v) => {
                                setFromLoading(true)
                                debouncedFetchFrom(v)
                            }} placeholder='From' id='from' name='from' onChange={(e, v) => {

                                setMileage((prev) => {
                                    return {
                                        ...prev,
                                        from: v.description,
                                        fromId: v.place_id
                                    }
                                })
                            }} endDecorator={fromLoading && <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />} />
                        </div>
                        <div>
                            <label htmlFor='to'>To:</label>
                            <Autocomplete options={toSuggestions} loading={toLoading} getOptionLabel={option => option.description} onInputChange={(event, value) => {
                                if (value.length !== 0) {
                                    setToLoading(true)
                                    debouncedFetchTo(value)
                                }
                            }} disableClearable={true} placeholder='To' onChange={(e, v) => {
                                setMileage((prev) => {
                                    return {
                                        ...prev,
                                        to: v.description,
                                        toId: v.place_id
                                    }
                                })
                            }} />

                        </div>

                    </div>
                </div>

                <div style={{ display: 'flex', gap: 20, flexDirection: window.innerWidth < 600 ? 'column' : 'row' }}>
                    <div>
                        <Typography fontSize={'lg'} fontWeight={'bold'}>Mileage Count:</Typography>
                        <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                            {/* <Input mile /> */}
                            <div>
                                <label htmlFor='km_auto'>Kilometers (calculated): </label>
                                <Input type='number' value={mileage.km_auto} disabled name='km_auto' id='km_auto'></Input>
                            </div>
                            <div>
                                <label htmlFor='km_manual'>Kilometers (manual): </label>
                                <Input type='number' value={mileage.km_manual} name='km_manual' id='km_manual' onChange={handleChange}></Input>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Typography fontSize={'lg'} fontWeight={'bold'}>Charges:</Typography>
                        <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                            <div>
                                <label htmlFor='bridge'>Bridge: </label>
                                <Input type='number' value={mileage.bridge} name='bridge' id='bridge' onChange={handleChange}></Input>
                            </div>
                            <div>
                                <label htmlFor='parking'>Parking: </label>
                                <Input type='number' value={mileage.parking} name='parking' id='parking' onChange={handleChange}></Input>
                            </div>
                        </div>

                    </div>
                </div>
                {mileage.km_auto != mileage.km_manual && <div style={{ width: '100%' }}>
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Comment:</Typography>
                    <div style={{ display: 'flex', gap: 4, marginTop: 5, width: '100%' }}>
                        {/* <Input mile /> */}
                        <Input type='text' value={mileage.comment} name='comment' id='comment' placeholder='' fullWidth onChange={handleChange}></Input>
                    </div>
                </div>}

                <div>
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Attachments(Optional):</Typography>
                    <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                        <Input type='file' onChange={(e) => {
                            if (e.target.files?.[0]) {
                                const size = e.target.files[0].size;

                                if (size > 4 * 1024 * 1024) {
                                    alert('File size should be less than 4mb')
                                    e.target.value = null as any
                                    return;
                                }
                            }
                            setAttachement(e.target.files?.[0])
                        }} ref={fileRef}></Input>
                    </div>
                </div>
                <div style={{ marginBottom: 10 }}>

                    <Button onClick={handleSubmit}>Submit Mileage</Button>
                </div>
            </div>

        </div >
    );
};

export default SubmitMileage;