import { Box, Button, FormControl, FormLabel, Input, Link, Option, Select, Sheet, Stack, Table } from "@mui/joy"
import { useAuth } from "../../providers/AuthContext";
import { AnyCnameRecord } from "dns";
import React, { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import LaunchIcon from '@mui/icons-material/Launch';
import { User } from "../../types";
import DeleteIcon from '@mui/icons-material/Delete';
import { ComponentLoader } from "../../ui/Loader";
import { Locations } from "../../constants";

const Staff: React.FC<{ role: string }> = function ({ role }) {
    const auth = useAuth();
    const [loader, setLoader] = useState(false);
    const [addLoader, setAddLoader] = useState(false)
    const [userState, setUserState] = useState({
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        location: Locations[0],
        role: role
    });
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const [refresh, setRefresh] = useState(true);
    const [users, setUsers] = useState<Array<User>>([]);
    useEffect(() => {
        async function fetchUsers() {
            try {
                // toast.success(userState.role)
                setLoader(true);
                const data = await fetch(`${process.env.REACT_APP_server}/user?role=${role}`, {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                const res = await data.json();
                // setLoader(false);
                if (res.success) {
                    setUsers(res.data);
                }
                setLoader(false);
            } catch (error) {
                console.log(error);
                setLoader(false);
            }
        }
        fetchUsers()
    }, [role, refresh])
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Your submit logic here
        // alert(userState.username)
        try {
            setAddLoader(true)
            // alert(Date.now().toString())
            const data = await fetch(`${process.env.REACT_APP_server}/user`, {
                method: "POST",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),

                },
                body: JSON.stringify({ ...userState, role: role, username: userState.username.length === 0 ? Date.now().toString() : userState.username, password: userState.password.length === 0 ? Date.now().toString() : userState.password })
            })
            const res = await data.json();
            setAddLoader(false)
            if (res.success) {
                toast.success('User added');
                setRefresh((prev) => !prev);
            }
            else {
                toast.error(res.error ? JSON.stringify(res.error) : 'error');
            }
        } catch (error) {
            console.log(error);
            setAddLoader(false);
        }
    };

    async function handleDelete(id: string, status: string) {
        try {
            const data = await fetch(`${process.env.REACT_APP_server}/user/${id}?disabled=${status}`, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            const res = await data.json()
            if (res.success) {
                toast.success(status ? 'User disabled' : 'User enabled');
                setRefresh((prev) => !prev);
            }
            else {
                toast.error(res.error ? JSON.stringify(res.error) : 'Some error');
            }

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <form style={{ width: '70dwv', overflowX: 'scroll' }} onSubmit={handleSubmit}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <FormLabel>First Name</FormLabel>
                            <Input type="text" name='first_name' placeholder="First Name" value={userState.first_name} onChange={handleInputChange} required />
                        </div>
                        <div>
                            <FormLabel>Last Name</FormLabel>
                            <Input type='text' name='last_name' placeholder="Last Name" value={userState.last_name} onChange={handleInputChange} required />
                        </div>
                        {role !== 'member' && <div>
                            <FormLabel>Username</FormLabel>
                            <Input type='text' name='username' placeholder="Username" value={userState.username} onChange={handleInputChange} required />
                        </div>}
                        {role !== 'member' && <div>
                            <FormLabel>Password</FormLabel>
                            <Input type='password' name='password' placeholder="Password" value={userState.password} onChange={handleInputChange} required />
                        </div>}


                        <div>
                            <FormLabel>Location</FormLabel>
                            <Select value={userState.location} name="location" onChange={(e, value) => {
                                if (value) {
                                    
                                    setUserState((prev) => {
                                        return {
                                            ...prev,
                                            location: value
                                        }
                                    })
                                }
                            }}>
                                {Locations.map((location, i) => <Option key={i} value={location}>{location}</Option>)}
                            </Select>
                        </div>


                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="submit" sx={{ mt: 2 }} loading={addLoader}>{role === 'employee' ? 'Add Employee' : role === 'coordinator' ? 'Add Coordinator/Payroll' : 'Add Member'}</Button>
                    </div>

                </form>
            </div>
            <div style={{ cursor: 'pointer' }}>
                {loader ? <ComponentLoader /> : <Sheet sx={{ height: '60dvh', overflow: 'auto' }}>
                    <Table aria-label="table 'outlined's" variant={'soft'} sx={{
                        overflowX: 'scroll',
                        width: '100%',
                        mt: 1
                        // paddingLeft: 1
                    }} stickyHeader={true}>
                        <thead >
                            <tr style={{ backgroundColor: 'black', color: 'white' }}>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>First Name</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>Last Name</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>Location</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>Username</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>Password</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>Role</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 150 }}>Actions</th>
                            </tr>

                        </thead>

                        <tbody >
                            {users.map((user) => {
                                return <tr>
                                    <td>
                                        {user.first_name}
                                    </td>
                                    <td>
                                        {user.last_name}
                                    </td>
                                    <td>
                                        {<Select value={user.location} onChange={(e, value) => {
                                            async function updateRole() {
                                                try {
                                                    const data = await fetch(`${process.env.REACT_APP_server}/user/${user._id}`, {
                                                        method: "PUT",
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                                                        },
                                                        body: JSON.stringify({
                                                            location: value
                                                        })
                                                    });
                                                    const res = await data.json();
                                                    if (res.success) {
                                                        toast.success('Location updated')
                                                    }
                                                    else {
                                                        toast.error(res.error ? JSON.stringify(res.error) : "Some error")
                                                    }
                                                    setRefresh(prev => !prev)
                                                } catch (error) {
                                                    console.log(error);

                                                }
                                            }
                                            if (value) {
                                                // toast.success(value)
                                                updateRole();

                                            }
                                        }}>
                                            {
                                                Locations.map((location, i) => <Option value={location} key={i}>{location}</Option>)
                                            }
                                        </Select> }

                                    </td>
                                    <td>
                                        {user.username ? user.username : 'N/A'}
                                    </td>
                                    <td>
                                        {user.password ? user.password : "N/A"}
                                    </td>
                                    <td>
                                        {role !== 'member' ? <>{user.role}</> : <>Member</>}

                                    </td>
                                    <td>
                                        <div onClick={() => {
                                            handleDelete(user._id, user.disabled ? "false" : "true");
                                        }}>
                                            {user.disabled ? <Button variant="solid" color="success">Enable</Button> : <Button variant="solid" color="danger">
                                                Disable
                                            </Button>}
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>

                    </Table>
                </Sheet>}

            </div>
        </div>
    );
}


export default Staff;