import { Option, Select } from "@mui/joy";
import { FC } from "react";
// import helpers, { formatLocalDate } from "../../utils/helpers";

import * as React from 'react';
import Box from '@mui/joy/Box';

import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
// Icons import

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

import MenuIcon from '@mui/icons-material/Menu';

import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';

// custom
import Menu from './../../ui/Menu';
import Layout from './../../ui/Layout';
function Navbar({children} : {children : React.ReactNode}) {
  return (
    <Layout.Header sx={{

    }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1.5,
            }}
          >
            
            <IconButton
              size="lg"
              variant="soft"
              sx={{ display: { sm: 'inline-flex' } }}
            >
              <GroupRoundedIcon />
            </IconButton>
            <Typography component="h1" fontWeight="xl">
              Ssgn
            </Typography>
          </Box>
          {/* {children} */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
            <Menu
              id="app-selector"
              control={
                <IconButton
                  size="lg"
                  variant="soft"
                  color="neutral"
                  aria-label="Apps"
                >
                  <GridViewRoundedIcon />
                </IconButton>
              }
              menus={[
                {
                  label: 'Email',
                  href: '/joy-ui/getting-started/templates/email/',
                },
                {
                  label: 'Team',
                  active: true,
                  href: '/joy-ui/getting-started/templates/team/',
                  'aria-current': 'page',
                },
                {
                  label: 'Files',
                  href: '/joy-ui/getting-started/templates/files/',
                },
              ]}
            />
            {/* <ColorSchemeToggle /> */}
          </Box>
        </Layout.Header>
  )
}

interface AvailablWeeksProps {
    weeks: string[];
    selectedWeek: string;
    setSelectedWeek:  React.Dispatch<React.SetStateAction<string>>
    handleDateChange : (value : string) => void
}
const AvailableWeeks: FC<AvailablWeeksProps> = function ({
    weeks,
    selectedWeek,
    setSelectedWeek,
    handleDateChange
}) {
    return (
       
             <div className="available-weeks" style={{marginTop: 20, marginBottom: 20}}>
            <Select
                onChange={(e, value) => {
                    if (value) {
                        setSelectedWeek(value);
                    }
                }}
                variant="soft"
                sx={{
                    width: 300
                }}
                value={selectedWeek}
            >
                {weeks.map((week, idx) => {
                    return (
                        <Option
                            key={idx}
                            value={week}
                        >
                            {week}
                        </Option>
                    );
                })}
            </Select>
        </div>

       
    );
};

export default AvailableWeeks;
