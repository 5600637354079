// AuthContext.tsx
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface User {
    _id : string;
    username: string;
    password : string;
    role : string;
    location? : string;
    first_name : string;
    last_name : string;
    // Add other user-related properties here
}

interface AuthContextProps {
    user: User | null;
    setUser : React.Dispatch<React.SetStateAction<User | null>>
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    authenticated: boolean | null;
    setAuthenticated : React.Dispatch<React.SetStateAction<boolean | null>>
    members : User[]
    setMembers: React.Dispatch<React.SetStateAction<User[]>>
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = React.useState<User | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean | null>(null)
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState<Array<User>>([]);
    const logout = () => {
        // Implement logout logic
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{authenticated, setAuthenticated, user, setUser, loading, setLoading, members, setMembers}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
