import { Table, Input, Select, Sheet, Button, Chip } from "@mui/joy";
import { ShiftType, User } from "../../types";
import { hour_types } from "../../utils/helpers";
import LaunchIcon from '@mui/icons-material/Launch';
import { FC } from "react";
import { Link } from "react-router-dom";


interface EntryProps {
    entries: Array<{ _id: string, user_id: User, approved: boolean, createdAt: Date, daysWorked: number }>
}
const Entries: FC<EntryProps> = function ({ entries }) {
    console.log('entry comp');

    return (
        <Sheet sx={{ height: '60dvh', overflow: 'auto' }}>
            <Table aria-label="table 'outlined's" variant={'soft'} sx={{
                overflowX: 'scroll',
                width: '100%',
                // paddingLeft: 1
                mt: 1
            }} stickyHeader={true}>
                <thead >
                    <tr style={{ backgroundColor: 'black', color: 'white' }}>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>First Name</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Last Name</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Days Worked</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Location</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Username</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Actions</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Status</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Time created</th>
                    </tr>

                </thead>

                <tbody >

                    {entries?.map((entry) => {
                        return (
                            <tr>
                                <td>{entry.user_id.first_name}</td>
                                <td>{entry.user_id.last_name}</td>
                                <td>
                                    {entry.daysWorked !== undefined ? entry.daysWorked : "N/A"}
                                </td>
                                <td>{entry.user_id.location}</td>
                                <td>{entry.user_id.username}</td>
                                {entry._id ? <td style={{ display: 'flex', alignItems: 'center' }}>
                                    <Link to={`/timesheets/${entry._id}`} style={{
                                        color: 'black',
                                        marginRight: 10
                                    }}><LaunchIcon /></Link>
                                    <Button variant="outlined" size="sm" color="success" onClick={() => {
                                        fetch(`${process.env.REACT_APP_server}/timesheets/file/weekly/${entry._id}`, {
                                            method: "GET",
                                            headers: {
                                                'Content-type': 'application/json',
                                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                                            }
                                        }).then((data) => data.blob()).then((blob) => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = entry?.user_id?.first_name + " " + entry?.user_id?.last_name
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                            window.URL.revokeObjectURL(url);
                                        }).catch((err) => {
                                            console.log(err);
                                        })
                                    }}>Download CSV</Button>
                                </td> : "N/A"
                                }
                                <td>
                                    {<Chip color={`${entry.approved ? 'success' : 'warning'}`}>{entry.approved ? 'Approved' : 'Unapproved'}</Chip>}
                                </td>
                                <td>
                                    {entry.createdAt ? new Date(entry.createdAt as any).toLocaleString() : "N/A"}
                                </td>

                            </tr>
                        )
                    })}



                </tbody>

            </Table>
        </Sheet>
    )
}


export default Entries;