import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../ui/Loader';

interface GuardProps {
    // Define component props here
}

const Guard: FC<GuardProps> = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate('/', { replace: true })
        }, 3000)
        return () => window.clearTimeout(timeout)
    }, [])
    return (
        <div>
            <Loader />
        </div>
    );
};

export default Guard;
