import { FC, useEffect, useState } from 'react';
import Navbar from '../../ui/Navbar';
import { Autocomplete, Button, Tab, TabList, Tabs, Typography } from '@mui/joy';
import { User } from '../../types';
import { ComponentLoader, Loader } from '../../ui/Loader';
import { getLast12Months } from '../../utils/helpers';
import ApprovedMileages from '../coordinator/ApprovedMileages';
import PendingMileages from '../coordinator/PendingMileages';
import toast from 'react-hot-toast';

interface MileagesProps {
    // Define component props here
}

const Mileages: FC<MileagesProps> = () => {
    const [suggestions, setSuggestions] = useState({
        employees: [] as User[],
        members: [] as User[]
    })
    const [employee, setEmployee] = useState<User | null>(null)
    const [member, setMember] = useState<User | null>(null)
    const [month, setMonth] = useState<string>(getLast12Months()[0])
    const [sugLoading, setSugLoading] = useState(false)
    useEffect(() => {
        async function getSuggestions() {
            setSugLoading(true)
            const response = await fetch(`${process.env.REACT_APP_server}/mileage/usersuggestions`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            setSugLoading(false)
            const data = await response.json()
            setSuggestions(data)
            return data;
        }
        getSuggestions()
    }, [])

    const [index, setIndex] = useState(0)
    const [entries, setEntries] = useState([])
    useEffect(() => {
        async function fetchMileages() {

        }
    }, [])
    const [fetchingMileage, setFetchingMileage] = useState(false)
    const [mileages, setMileages] = useState<Array<{
        _id: string
        username: string
        member: string
        approved: boolean
        approver?: string
        totalKm: number
        totalParking: number
        totalBridge: number
    }>>([])
    async function handleSubmit() {
        if (!employee && !member) {
            toast.error("Employee or member required")
            return
        }
        if (!month) {
            toast.error("Month required")
            return
        }

        try {
            setFetchingMileage(true)
            // await new Promise((resolve) => setTimeout(resolve, 2000))
            const response = await fetch(`${process.env.REACT_APP_server}/mileage/entries`, {
                method: 'POST',
                body: JSON.stringify({ username: employee?.username, member: member ? member?.first_name + " " + member?.last_name : undefined, month }),
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            const data = await response.json()
            setFetchingMileage(false)
            if (data.error) {
                toast.error(data.error)
                return
            }
            setMileages(data.map((item: any) => item))
        } catch (error) {
            setFetchingMileage(false)
            toast.error("Something went wrong")
            return
        }
    }
    if (sugLoading) {
        return <Loader />
    }
    return (
        <div style={{ marginLeft: 25, marginRight: 25 }}>
            <Navbar />

            <div style={{
                display: 'flex',
                gap: 5
            }}>
                <div>
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Username:</Typography>
                    <Autocomplete options={suggestions.employees} value={employee} getOptionLabel={option => option.first_name + " " + option.last_name} onChange={(e, v) => {
                        setEmployee(v)
                    }}></Autocomplete>
                </div>
                <div>
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Member:</Typography>
                    <Autocomplete options={suggestions.members} value={member} getOptionLabel={option => option.first_name + " " + option.last_name} onChange={(e, v) => {
                        setMember(v)
                    }}></Autocomplete>
                </div>

                <div>
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Month:</Typography>
                    <Autocomplete options={getLast12Months()} value={month} disableClearable={true} onChange={(e, v) => setMonth(v)}></Autocomplete>
                </div>
            </div>
            <Button style={{ marginTop: 20 }} onClick={handleSubmit} loading={fetchingMileage}>Search</Button>
            {fetchingMileage ? <ComponentLoader /> : <div style={{
                marginTop: 20
            }}>
                <Tabs
                    aria-label="Soft tabs"
                    value={index}
                    onChange={(event, value) => setIndex(value as number)}

                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}
                >
                    <TabList variant="plain" tabFlex={1}>
                        <Tab
                            variant={index === 0 ? 'solid' : 'plain'}
                            color={'neutral'}
                        >
                            Approved
                        </Tab>
                        <Tab
                            variant={index === 1 ? 'solid' : 'plain'}
                            color={'neutral'}

                        >
                            Unapproved
                        </Tab>
                    </TabList>
                </Tabs>
                {index == 0 && <ApprovedMileages entries={mileages.filter((mileage) => mileage.approved)} refresh={handleSubmit} month={month} />}
                {index == 1 && <PendingMileages entries={mileages.filter((mileage) => !mileage.approved)} refresh={handleSubmit} month={month} />}
            </div>}
        </div>
    );
};

export default Mileages;