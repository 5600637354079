import { CircularProgress } from '@mui/joy'
import React from 'react'

export const Loader = () => {
    return (
        <div style={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress
                color="neutral"
                determinate={false}
                size="lg"
                variant="plain"
            />
        </div>
    )
}

export const ComponentLoader = () => {
    return (
        <div style={{
            height: '40dvh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress
                color="neutral"
                determinate={false}
                size="lg"
                variant="plain"
            />
        </div>
    )
}
