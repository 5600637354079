import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import '@fontsource/balsamiq-sans';
import JoySignInSideTemplate from './modules/auth/Login';
import { useAuth } from './providers/AuthContext';
import { Loader } from './ui/Loader';
import toast, { Toaster } from 'react-hot-toast';
import Timesheet from './pages/Timesheet';
import WeeklyShifts from './modules/coordinator/WeeklySheets';
import { Redirect } from './utils/Redirect';
import MileageController from './modules/mileage/MileageController';
import SubmitMileage from './modules/mileage/SubmitMileage';
import SubmitTimesheet from './modules/timesheet/SubmitTimesheet';
import Guard from './modules/mileage/Guard';
import Mileages from './modules/mileage/Mileages';

function App() {
  // alert('soemthig')
  const user = useAuth();
  useEffect(() => {
    async function fetchUser() {
      try {
        if (user.authenticated === null && user.loading === false) {
          user.setLoading(true);
          // alert('just checking inside')
          const token = localStorage.getItem('token');
          const data = await fetch(`${process.env.REACT_APP_server}/user/me`, {
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          const res = await data.json();
          if (res.success && res.data) {
            user.setAuthenticated(true);
            user.setUser(res.data)
          }
          else {
            user.setAuthenticated(false);
            user.setUser(null)
          }
          user.setLoading(false);
        }
      } catch (error) {
        console.log(error);
        user.setLoading(false);
        user.setAuthenticated(false);
        user.setUser(null)
      }

    }
    fetchUser();
  }, [user])

  useEffect(() => {

    async function fetchMembers() {
      try {
        const data = await fetch(`${process.env.REACT_APP_server}/user/members`, {
          headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        });
        const res = await data.json();
        if (res.success) {
          // alert(JSON.stringify(res.data))
          user.setMembers(res.data);
        }
      } catch (error) {
        console.log(error);
      }

    }
    if (user.user != null) {
      fetchMembers();
    }
  }, [user.user])
  return (
    <div>
      <Toaster position='top-center'></Toaster>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={user.loading ? <Loader /> : user.authenticated ? <Home /> : <JoySignInSideTemplate />} />
          <Route path='/timesheet' element={user.loading ? <Loader /> : user.user?.role === 'admin' || user.user?.role === 'coordinator' ? <div className="home-page">
            <WeeklyShifts />
          </div> : <JoySignInSideTemplate />}></Route>
          <Route path='timesheets/:id' element={user.loading ? <Loader /> : user.user?.role === 'admin' || user.user?.role === 'coordinator' ? <Timesheet /> : <JoySignInSideTemplate />}></Route>
          <Route path='/mileage' element={user.loading ? <Loader /> : user.user?.role === 'employee' ? <SubmitMileage /> : <Guard />} />
          <Route path='/mileages' element={user.loading ? <Loader /> : user.user?.role === 'coordinator' || user.user?.role === 'admin' ? <Mileages /> : <Guard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
