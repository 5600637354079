
import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import { Option, Select } from '@mui/joy';
import { useAuth } from '../../providers/AuthContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {useState} from "react";
// import mod from './GoogleIcon';

interface FormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement;
    password: HTMLInputElement;
    role: HTMLSelectElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ColorSchemeToggle({ onClick, ...props }: IconButtonProps) {
    const { mode, setMode } = useColorScheme();
    setMode('light')
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <></>
    }
    return (
        <></>
    );
}

export default function JoySignInSideTemplate() {
    const [role, setRole] = React.useState("employee");
    const auth = useAuth();
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                gap: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton variant="soft" color="primary" size="sm">
                            <img src={`${process.env.PUBLIC_URL}/ssg3.png`} width={'40'}></img>
                            </IconButton>
                            <Typography level="title-lg">Support Services Group / SSG</Typography>
                        </Box>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography level="h3">Sign in</Typography>
                            </Stack>
                        </Stack>

                        <Stack gap={4} sx={{ mt: 2 }}>
                            <form
                                onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
                                    event.preventDefault();
                                    // alert(role)

                                    const formElements = event.currentTarget.elements;
                                    const payload = {
                                        // ro : formElements.role.value,
                                        username: formElements.username.value,
                                        password: formElements.password.value,
                                        role: role ? role : 'employee'
                                    };
                                    try {
                                        setLoader(true)
                                        const data = await fetch(`${process.env.REACT_APP_server}/user/login`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json'
                                            },
                                            body: JSON.stringify(payload)
                                        })

                                        const res = await data.json();
                                        if (res.success && res.data.user && res.data.token) {
                                            auth.setAuthenticated(true);
                                            auth.setLoading(false);
                                            auth.setUser(res.data.user);
                                            // toast.success('loggedin')
                                            navigate("/")
                                            localStorage.setItem("token", res.data.token)
                                        }
                                        else {
                                            toast.error(res.error ? JSON.stringify(res.error) : 'Error logging in, ensure correct credentials')
                                            auth.setAuthenticated(false);
                                            auth.setLoading(false);
                                            auth.setUser(null);
                                        }
                                        setLoader(false)

                                    } catch (error) {
                                        console.log(error);
                                        setLoader(false)
                                        auth.setAuthenticated(false);
                                        auth.setLoading(false);
                                        auth.setUser(null);
                                    }
                                    //   alert(JSON.stringify(data, null, 3));
                                }}
                            >
                                <FormControl required>
                                    <FormLabel>Username</FormLabel>
                                    <Input type="text" name="username" />
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" name="password" />
                                </FormControl>

                                <FormControl required>
                                    <FormLabel>Select Role</FormLabel>
                                    <Select value={role} placeholder='Select Role' name='role' onChange={(e, value) => {
                                        // toast.error(value);
                                        if (value) setRole(value as string)
                                    }}>
                                        <Option value={'employee'} >Employee</Option>
                                        <Option value={'coordinator'}>Coordinator/Payroll</Option>
                                        <Option value='admin'>Admin</Option>
                                    </Select>
                                </FormControl>
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                    </Box>
                                    <Button type="submit" fullWidth loading={loader}>
                                        Sign in
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            © Support Services Group {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        `url(${process.env.PUBLIC_URL}/ssg.png)`,
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                        `url(${process.env.PUBLIC_URL}/ssg.png)`,
                    },
                })}
            />
        </CssVarsProvider>
    );
}
