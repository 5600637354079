import { useEffect, useState } from "react";
import {
    calculateHours,
    calculateHours24,
    formattedDates,
    getDatesInRange
} from "../../utils/helpers";
import AvailableWeeks from "./AvailableWeeks";
import Dates from "./Dates";
import { Timesheet } from "../../types";
import Navbar from "../../ui/Navbar";
import { Button, Checkbox, Snackbar, Tab, TabList, Tabs, Typography } from "@mui/joy";
import moment from "moment-timezone";
import toast from "react-hot-toast";
import PastSheets from "../employee/PastSheets";
import PastMileages from "../mileage/PastMileages";




function SubmitTimesheet() {
    const dateMapping = formattedDates();
    const weeks = Array.from(dateMapping.keys());
    const [selectedWeek, setSelectedWeek] = useState(weeks[0]);
    const [checked, setChecked] = useState(false);
    const handleDateChange = function (value: string) {

    }

    const [dates, setDates] = useState(getDatesInRange(dateMapping.get(weeks[0])?.start_date, dateMapping.get(weeks[0])?.end_date))

    const [timesheet, setTimesheet] = useState<Timesheet>({
        start_date: dates[0],
        end_date: dates[dates.length - 1],
        days: []

    })

    useEffect(() => {
        const days = getDatesInRange(dateMapping.get(selectedWeek)?.start_date, dateMapping.get(selectedWeek)?.end_date)
        setDates(days)
        setTimesheet({
            start_date: days[0],
            end_date: days[days.length - 1],
            days: []
        })
    }, [selectedWeek])

    const [total, setTotal] = useState({
        regular_hours: 0,
        night_awake_hours: 0,
        night_sleep_hours: 0,
        on_call_regular: 0,
        on_call_night_sleep: 0,
        team_lead_regular: 0,
        team_lead_night_awake: 0,
        team_lead_sleep: 0,
        team_lead_training: 0,
        training_hours: 0,
        sick_hours_regular: 0,
        sick_hours_night_awake: 0,
        sick_hours_night_sleep: 0,
        vacation_hours_regular: 0,
        vacation_hours_night_awake: 0,
        vacation_hours_night_sleep: 0,
    })
    useEffect(() => {
        let total_hours = {
            regular_hours: 0,
            night_awake_hours: 0,
            night_sleep_hours: 0,
            on_call_regular: 0,
            on_call_night_sleep: 0,
            team_lead_regular: 0,
            team_lead_night_awake: 0,
            team_lead_sleep: 0,
            team_lead_training: 0,
            training_hours: 0,
            sick_hours_regular: 0,
            sick_hours_night_awake: 0,
            sick_hours_night_sleep: 0,
            vacation_hours_regular: 0,
            vacation_hours_night_awake: 0,
            vacation_hours_night_sleep: 0,
        };


        timesheet.days.forEach((day) => {
            day.shifts.forEach((shift) => {
                const hours = calculateHours24(shift.start_time, shift.end_time);
                switch (shift.hour_type) {
                    case "Regular Hours":
                    case "Training Hours":
                    case "On Call Regular":
                        total_hours.regular_hours += hours;
                        break;
                    case 'Team Lead Regular':
                    case 'Team Lead Night Awake':
                    case 'Team Lead Training':
                        total_hours.team_lead_regular += hours;
                        break;
                    case 'Team Lead Night Sleep':
                        total_hours.team_lead_sleep += hours;
                        break;
                    case 'Night Awake Hours':
                        total_hours.night_awake_hours += hours;
                        break;
                    case 'Night Sleep Hours':
                    case 'On Call Night Sleep':
                        total_hours.night_sleep_hours += hours;
                        break;
                    case 'Sick Hours (Regular)':
                    case 'Sick Hours (Night Awake)':
                        total_hours.sick_hours_regular += hours;
                        break;
                    case 'Sick Hours (Night Sleep)':
                        total_hours.sick_hours_night_sleep += hours;
                        break;
                    case 'Vacation Hours (Regular)':
                    case 'Vacation Hours (Night Awake)':
                        total_hours.vacation_hours_regular += hours;
                        break;
                    case 'Vacation Hours (Night Sleep)':
                        total_hours.vacation_hours_night_sleep += hours;
                        break;
                    default:
                        break;
                }
            })
        })
        setTotal(total_hours);
    }, [timesheet]);


    async function handleSubmit() {
        if (!checked) {
            toast.error("You need to agree with conditions, mark the checkbox");
            return;
        }
        const start_date = moment(timesheet.start_date).format("YYYY-MM-DD");
        const end_date = moment(timesheet.end_date).format("YYYY-MM-DD");
        fetch(process.env.REACT_APP_server + '/timesheets', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                ...timesheet, start_date: start_date, end_date: end_date, total_hours_by_hour: {
                    regular_hours: total.regular_hours,
                    team_lead_regular: total.team_lead_regular,
                    team_lead_sleep: total.team_lead_sleep,
                    night_awake_hours: total.night_awake_hours,
                    night_sleep_hours: total.night_sleep_hours,
                    sick_hours: total.sick_hours_regular,
                    sick_night_sleep: total.sick_hours_night_sleep,
                    vacation_hours: total.vacation_hours_regular,
                    vacation_night_sleep: total.vacation_hours_night_sleep,
                }
            })
        }).then((data) => {

            return data.json()
        }).then((res) => {
            if (res.data) {
                toast.success("Timsheet submitted!");
                setTimeout(() => {
                    window.location.reload();
                }, 1200)
            }
            else {
                toast.error(res.error ? res.error : 'Internal server error');
            }

        }).catch((err) => {
            console.log(err);
        })
    }

    const [index, setIndex] = useState(0)
    return (
        <div className="employee-submit-page">
            <Navbar />
            <Tabs
                aria-label="Soft tabs"
                value={index}
                onChange={(event, value) => setIndex(value as number)}

                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <TabList variant="plain" tabFlex={1}>
                    <Tab
                        variant={index === 0 ? 'solid' : 'plain'}
                        color={'neutral'}
                    >
                        Submit Timsheet
                    </Tab>
                    <Tab
                        variant={index === 1 ? 'solid' : 'plain'}
                        color={'neutral'}

                    >
                        View Past Timesheets
                    </Tab >
                    <Tab variant={index == 2 ? 'solid' : 'plain'} color="neutral">
                        View Past Mileages
                    </Tab>
                </TabList>
            </Tabs>
            {index === 0 ? <> <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AvailableWeeks weeks={weeks} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} handleDateChange={handleDateChange}></AvailableWeeks>
            </div>

                <Dates dates={dates} timesheet={timesheet} setTimesheet={setTimesheet} />

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
                    {/* @ts-igore */}
                    <div style={{ display: 'flex' }}>
                        <Checkbox onChange={(e) => setChecked(e.target.checked)} ></Checkbox>
                        <Typography fontSize={'smaller'} sx={{ ml: 1, mb: 2 }}>
                            I hereby certify that, to the best of my knowledge, the provided
                            information is true and accurate.
                        </Typography>
                    </div>

                    <Button onClick={handleSubmit}>Submit Timesheet</Button>
                </div>
                {/* <div></div> */}
            </> : index === 1 ? <><PastSheets />    </> : <>
                <PastMileages />
            </>}

        </div>
    )
}

export default SubmitTimesheet
