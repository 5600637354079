import { Button, Chip, Typography } from "@mui/joy";
import { Timesheet, User } from "../../types";
import { FC, useMemo, useState } from "react";
import Dates from "./Dates";
import toast from "react-hot-toast";
import { useAuth } from "../../providers/AuthContext";


interface TimesheetProps {
    timesheet : Timesheet & {user_id? : User};
    setTimesheet : React.Dispatch<React.SetStateAction<Timesheet & {
        user_id?: User;
    } >>

    handleUpdate : () => Promise<void>
    dates : Date[]

}
const TimesheetData : FC<TimesheetProps> = function ({timesheet, setTimesheet, handleUpdate, dates}) {
    const auth = useAuth();
    

    const isDeleteValid = useMemo(() => {
        if (auth.user?.role === 'employee' ) {
            let isValid = true;
            for (const day of timesheet.days) {
                for (const shift of day.shifts) {
                    if (shift.approved) {
                        return false;
                    }
                }
            }
            return true;
        }
        return false;
    }, [auth.user, timesheet])

    return (
        <div className="timesheet-data">
            <div className="timesheet-user" style={{ marginTop: 25, display: 'flex', justifyContent: 'center' }}>
                <div>
                    <Typography>Name:     {timesheet.user_id?.first_name + " " + timesheet.user_id?.last_name}</Typography>
                    <Typography>Username: {timesheet.user_id?.username}</Typography>
                    <Typography>Location: {timesheet.user_id?.location}</Typography>
                    <Typography>Time Created:     {(timesheet as any).createdAt ? new Date((timesheet as any).createdAt).toLocaleString() : 'N/A'}</Typography>
                </div>
            </div>
            <hr></hr>

            <div className="timesheet-status" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {auth.user?.role === 'employee' && isDeleteValid ? <Button variant="solid" color="danger" onClick={async () => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_server}/timesheets/${(timesheet as any)._id}`, {
                            method: "DELETE",
                            headers: {
                                'Content-type': 'application/json',
                                "Authorization" : "Bearer " + localStorage.getItem('token')
                            }
                        })
                        if (response.status === 200) {
                            toast.success("Timesheet deleted");
                            window.location.reload()
                        }
                        else {
                            const data = await response.json();
                            toast.error(data.error)                            
                        }
                    } catch (error) {
                        console.log(error)
                        return toast.error('Somethign went wrong')
                    }
                }}>Delete</Button> : '' }
                
                <div>
                <Typography>Status: </Typography><Chip color={`${timesheet.approved ? 'success' : 'warning'}`}>{timesheet.approved ? 'Approved' : 'Unapproved'}</Chip></div>
            </div>
            {auth.user && auth.user.role !== 'employee' && <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
                {/* <Button variant="solid" onClick={handleApprove}>{timesheet.approved ? "Unapprove" : "Approve"}</Button> */}
            </div>}
            <div className="timesheet-dates">
                
                <Dates dates={dates} timesheet={timesheet} setTimesheet={setTimesheet} />
            </div>

            
        </div>
    )
}

export default TimesheetData;