import { useParams } from "react-router-dom";
import Navbar from "../ui/Navbar";
import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthContext";
import { Timesheet, User } from "../types";
import { Loader } from "../ui/Loader";
import TimesheetData from "../modules/timesheet/TimesheetData";
import {
    calculateHours,
    calculateHours24,
    getDatesInRange
} from "../utils/helpers";
import { Button, Typography } from "@mui/joy";
import toast from "react-hot-toast";
import {HoursByType} from "../modules/common/HoursByType";
// import { timeStamp } from "console";

const TimeSheet = function() {
    const auth = useAuth();
    const params = useParams();
    const[loader, setLoader] = useState(false);
    const [timesheet, setTimesheet] = useState<Timesheet & {user_id? : User}>({
        days: [],
        start_date: new Date(),
        end_date: new Date(),
    });
    const [dates, setDates] = useState<Array<Date>>([])
    useEffect(() => {
        async function fetchTimesheet() {
            try {
                
                if (params.id && auth.user && auth.authenticated) {
                    setLoader(true);
                    const data = await fetch(`${process.env.REACT_APP_server}/timesheets/${params.id}`, {
                        headers: {
                            'Content-type' : 'application/json',
                            'Authorization' : 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    const res = await data.json();
                    if (res.success && res.data) {
                        setTimesheet(res.data)
                        const sheet : Timesheet & {user_id : User} = res.data
                        const start_date = new Date(sheet.start_date)
                        const end_date = new Date(sheet.end_date);
                        
                        start_date.setMinutes(start_date.getMinutes() + start_date.getTimezoneOffset());
                        start_date.setHours(0, 0, 0, 0);
                        end_date.setMinutes(end_date.getMinutes() + end_date.getTimezoneOffset());
                        end_date.setHours(0, 0, 0, 0);

                       
                        setDates(getDatesInRange(start_date, end_date));
                    }
                    setLoader(false);
                }
            } catch (error) {
                console.log(error);
                setLoader(false);
            }
        }
        fetchTimesheet()
    }, [auth.user, params])
    const handleUpdate = async function() {
        try {
            
            //@ts-ignore
            const id = timesheet._id;
            if (!id) {

                toast.error('Need timesheet!')
                return;
            }
            // setLoader(true);
            const data = await fetch(`${process.env.REACT_APP_server}/timesheets/${id}`, {
                method: "PUT",
                headers: {
                    'Content-type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({...timesheet, total_hours_by_hour: { regular_hours: total.regular_hours,
                    team_lead_regular: total.team_lead_regular,
                    team_lead_sleep: total.team_lead_sleep,
                    night_awake_hours: total.night_awake_hours,
                    night_sleep_hours: total.night_sleep_hours,
                    sick_hours: total.sick_hours_regular,
                    sick_night_sleep: total.sick_hours_night_sleep,
                    vacation_hours: total.vacation_hours_regular,
                    vacation_night_sleep: total.vacation_hours_night_sleep }})
            })
            const res = await data.json();
            if (res.success) {
                toast.success('Timesheet updated!');
                setTimeout(() => {
                    window.location.reload()
                }, 1200)
            }
            else {
                toast.error(res.error ? JSON.stringify(res.error): 'Some error');
            }
            setLoader(false);
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
        
    }

    const [total, setTotal] = useState({
        regular_hours: 0,
        night_awake_hours: 0,
        night_sleep_hours: 0,
        on_call_regular: 0,
        on_call_night_sleep: 0,
        team_lead_regular: 0,
        team_lead_night_awake: 0,
        team_lead_sleep: 0,
        team_lead_training: 0,
        training_hours: 0,
        sick_hours_regular: 0,
        sick_hours_night_awake: 0,
        sick_hours_night_sleep: 0,
        vacation_hours_regular: 0,
        vacation_hours_night_awake: 0,
        vacation_hours_night_sleep: 0,
    })
    useEffect(() => {
        let total_hours = {
            regular_hours: 0,
            night_awake_hours: 0,
            night_sleep_hours: 0,
            on_call_regular: 0,
            on_call_night_sleep: 0,
            team_lead_regular: 0,
            team_lead_night_awake: 0,
            team_lead_sleep: 0,
            team_lead_training: 0,
            training_hours: 0,
            sick_hours_regular: 0,
            sick_hours_night_awake: 0,
            sick_hours_night_sleep: 0,
            vacation_hours_regular: 0,
            vacation_hours_night_awake: 0,
            vacation_hours_night_sleep: 0,
        };


        timesheet.days.forEach((day) => {
            day.shifts.forEach((shift) => {
                const hours = calculateHours24(shift.start_time, shift.end_time);
                switch (shift.hour_type) {
                    case "Regular Hours":
                    case "Training Hours":
                    case "On Call Regular":
                        total_hours.regular_hours += hours;
                        break;
                    case 'Team Lead Regular':
                    case 'Team Lead Night Awake':
                    case 'Team Lead Training':
                        total_hours.team_lead_regular += hours;
                        break;
                    case 'Team Lead Night Sleep':
                        total_hours.team_lead_sleep += hours;
                        break;
                    case 'Night Awake Hours':
                        total_hours.night_awake_hours += hours;
                        break;
                    case 'Night Sleep Hours':
                    case 'On Call Night Sleep':
                        total_hours.night_sleep_hours += hours;
                        break;
                    case 'Sick Hours (Regular)':
                    case 'Sick Hours (Night Awake)':
                        total_hours.sick_hours_regular += hours;
                        break;
                    case 'Sick Hours (Night Sleep)':
                        total_hours.sick_hours_night_sleep += hours;
                        break;
                    case 'Vacation Hours (Regular)':
                    case 'Vacation Hours (Night Awake)':
                        total_hours.vacation_hours_regular += hours;
                        break;
                    case 'Vacation Hours (Night Sleep)':
                        total_hours.vacation_hours_night_sleep += hours;
                        break;
                    default:
                        break;
                }
            })
        })
        setTotal(total_hours);
    }, [timesheet]);
    if (loader) {
        return <Loader />
    }

    // if (!timesheet) {
    //     <div>
    //         No such timesheet found
    //     </div>
    // }

    // const HoursByType = ({ total }) => (
    //     <div>
    //       <Typography variant="body1">Total Hours:</Typography>
    //       {Object.entries(total).map(([hourType, hours]) => (
    //         <Typography key={hourType} variant="body2">
    //           {`${hourType.replace(/_/g, ' ')}: ${hours}`}
    //         </Typography>
    //       ))}
    //     </div>
    //   );      

    
    return (
        <div style={{marginLeft: 25, marginRight: 25}}>
            <Navbar />
            {timesheet.days.length > 0 ? <TimesheetData timesheet={timesheet} setTimesheet={setTimesheet} handleUpdate={handleUpdate} dates={dates}/>: <div>
                No such timesheet exist</div>}
            
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 25, marginBottom: 25}}>
            <Button variant="solid" color="primary" onClick={() => {
                handleUpdate()
            }}>Update Timesheet</Button>
            </div>
            <div>
                {/* <HoursByType total={total} /> */}
            </div>
        </div>
    )
}


export default TimeSheet;