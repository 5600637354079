import { Table, Input, Select, Sheet, Button, Chip } from "@mui/joy";
import { Mileage, ShiftType, User } from "../../types";
import { hour_types } from "../../utils/helpers";
import LaunchIcon from '@mui/icons-material/Launch';
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MilageEntries from "../mileage/MilageEntries";
import toast from "react-hot-toast";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface PendingMileageProps {
    entries: Mileage[]
    refresh: () => Promise<void>
    month: string
}
const PendingMileages: FC<PendingMileageProps> = function ({ entries, refresh, month }) {
    console.log('Mileage comp');
    const [isOpen, setIsOpen] = useState(false)
    const [id, setId] = useState<string>()
    function onOpenChange() {
        setIsOpen(prev => !prev)
        if (isOpen) {
            refresh()
        }
    }
    async function approve(id: string, approve: boolean) {
        try {
            const response = await fetch(process.env.REACT_APP_server + "/mileage/" + id + "/approve", {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })

            const data = await response.json()

            if (data.error || !data.success) {
                toast.error(data.error || "Something went wrong")
                return
            }

            toast.success(!approve ? "Approved successfully" : "Unapproved successfully")
            refresh()
        } catch (error) {
            console.log(error)
        }
    }




    return (
        <Sheet sx={{ height: '60dvh', overflow: 'auto' }}>
            <MilageEntries isOpen={isOpen} onOpenChange={onOpenChange} id={id!} month={month} user={entries?.[0]?.username} />
            <Table aria-label="table 'outlined's" variant={'soft'} sx={{
                overflowX: 'scroll',
                width: '100%',
                // paddingLeft: 1
                mt: 1
            }} stickyHeader={true}>
                <thead >
                    <tr style={{ backgroundColor: 'black', color: 'white' }}>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>User</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Member</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Status</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Total Mileage</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Total Expense</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Action</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Time created</th>
                    </tr>

                </thead>

                <tbody >

                    {entries?.map((entry) => {
                        return (
                            <tr onDoubleClick={() => {
                                setId(entry._id)
                                onOpenChange()
                            }}>
                                <td>{entry.username}</td>
                                <td>{entry.member}</td>
                                <td>{entry.approved ? <Button color="danger" onClick={() => approve(entry._id, !entry.approved)}>Unapprove</Button> : <Button color="success" onClick={() => approve(entry._id, entry.approved)}>Approve</Button>}</td>
                                <td>{entry.totalKm}</td>
                                <td>{entry.totalBridge + entry.totalParking}</td>
                                <td style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 12
                                }}>
                                    <div onClick={() => {
                                        setId(entry._id)
                                        onOpenChange()
                                    }}>
                                        <OpenInNewIcon style={{
                                            cursor: 'pointer',
                                            // fontSize: 
                                        }} />
                                    </div>
                                    <Button onClick={() => [
                                        fetch(`${process.env.REACT_APP_server}/mileage/${entry._id}/download`, {
                                            method: "GET",
                                            headers: {
                                                'Content-type': 'application/json',
                                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                                            }
                                        }).then((data) => data.blob()).then((blob) => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = entry.username + "-" + entry.member + "-(" + month + ")"
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                            window.URL.revokeObjectURL(url);
                                        }).catch((err) => {
                                            console.log(err);
                                        })
                                    ]}>Download</Button></td>
                                <td>{entry.timestamp && new Date(entry.timestamp).toLocaleDateString()}</td>
                                {/* {entry._id ? <td style={{ display: 'flex', alignItems: 'center' }}>
                                    <Link to={`/timesheets/${entry._id}`} style={{
                                        color: 'black',
                                        marginRight: 10
                                    }}><LaunchIcon /></Link>
                                    <Button variant="outlined" size="sm" color="success" onClick={() => {
                                        fetch(`${process.env.REACT_APP_server}/timesheets/file/weekly/${entry._id}`, {
                                            method: "GET",
                                            headers: {
                                                'Content-type': 'application/json',
                                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                                            }
                                        }).then((data) => data.blob()).then((blob) => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = entry?.user_id?.first_name + " " + entry?.user_id?.last_name
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                            window.URL.revokeObjectURL(url);
                                        }).catch((err) => {
                                            console.log(err);
                                        })
                                    }}>Download CSV</Button>
                                </td> : "N/A"
                                } */}
                            </tr>
                        )
                    })}



                </tbody>

            </Table>
        </Sheet>
    )
}


export default PendingMileages;