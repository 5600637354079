import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Entries as MileageEntries } from '../../types';
import { Loader } from '../../ui/Loader';
import SubmitMileage from './SubmitMileage';
import Entry from './Entry';
import { Divider } from '@mui/joy';

interface EntriesProps {
    // Define component props here
    id: string
}

const Entries: FC<EntriesProps> = ({ id }) => {
    const [entries, setEntries] = useState<{ _id: string, member: string, approved: boolean, entries: MileageEntries[] }>()
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {

        async function fetchMileageEntries() {
            try {

                setLoading(true)
                const response = await fetch(process.env.REACT_APP_server + "/mileage/" + id, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const data = await response.json()

                setLoading(false)
                if (data.error) {
                    toast.error("No mileage found!")
                    return;
                }
                setEntries(data)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchMileageEntries()
    }, [id, refresh])
    function refreshEntries() {
        setRefresh(prev => !prev)
    }
    if (loading) {
        return <Loader />
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            {entries?.entries?.map((entry) => {
                return <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: 4 }}>
                    <Entry entry={entry} member={entries.member} refreshEntries={refreshEntries} />
                    <Divider />
                </div>
            })}
        </div>
    );
};

export default Entries;