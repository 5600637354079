import { Autocomplete, Divider, Typography } from '@mui/joy';
import { FC, useEffect, useState } from 'react';
import { ComponentLoader, Loader } from '../../ui/Loader';
import { Entries, MileageEntries } from '../../types';
import toast from 'react-hot-toast';
import Entry from './PastEntries';

interface PastMileagesProps {
    // Define component props here
}

const PastMileages: FC<PastMileagesProps> = () => {
    const [pastMileages, setPastMileages] = useState<{ _id: string, month: string, member: string }[]>([])
    const [loading, setLoading] = useState(true)
    const [chosenMileage, setChosenMileage] = useState<{ _id: string, month: string, member: string } | null>(null)
    const [entries, setEntries] = useState<Entries[]>([])
    const [entriesLoading, setEntriesLoading] = useState(false)
    useEffect(() => {
        async function fetchPastMileages() {
            try {
                setLoading(true)
                const response = await fetch(`${process.env.REACT_APP_server}/mileage`, {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const data = await response.json()

                if (!data.error) {
                    setChosenMileage(data[0])
                    setPastMileages(data)
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false)
            }
        }
        fetchPastMileages()
    }, [])
    const [refresh, setRefresh] = useState(false)
    function refreshEntries() {
        setRefresh(prev => !prev);
    }
    useEffect(() => {
        async function fetchMileageEntries() {
            try {
                setEntriesLoading(true)
                const response = await fetch(`${process.env.REACT_APP_server}/mileage/myentries/${chosenMileage?._id}/`, {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const data = await response.json();
                if (!data.error) {
                    setEntries(data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setEntriesLoading(false)
            }
        }
        if (chosenMileage) {
            fetchMileageEntries()
        }
    }, [chosenMileage, refresh])
    if (loading) {
        return <Loader />
    }
    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <Typography fontWeight={'bold'} fontSize={'lg'}>Select Month:</Typography>
                    <Autocomplete disableClearable={true} options={pastMileages} getOptionLabel={(option) => option.month + " " + option.member} value={chosenMileage!} onChange={(e, v) => {
                        setChosenMileage(v);
                    }} />
                </div>
            </div>

            {entriesLoading ? <ComponentLoader /> : <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                {entries.length === 0 && <div style={{ display: 'flex', marginTop: 20, justifyContent: 'center' }}>No Entries found!</div>}
                {entries?.map((entry) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: 4 }}>
                        <Entry entry={entry} member={chosenMileage?.member!} refreshEntries={refreshEntries} />
                        <Divider />
                    </div>
                })}
            </div>}
        </div>
    );
};

export default PastMileages;
