import { useEffect, useState } from "react"
import { useAuth } from "../../providers/AuthContext";
import {
    calculateHours,
    calculateHours24,
    formatLocalDate,
    getDatesInRange
} from "../../utils/helpers";
import { Timesheet, User } from "../../types";
import AvailableWeeks from "../timesheet/AvailableWeeks";
import TimeSheet from "../../pages/Timesheet";
import TimesheetData from "../timesheet/TimesheetData";
import toast from "react-hot-toast";
import { Button, Typography } from "@mui/joy";
import { ComponentLoader, Loader } from "../../ui/Loader";

const PastSheets = function () {
    const auth = useAuth();
    const [mapping, setMapping] = useState(new Map<string, Timesheet & {user_id? : User}>())
    const [loader, setLoader] = useState(false);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        async function fetchWeeks() {
            try {
                setLoader(true);
                const data = await fetch(`${process.env.REACT_APP_server}/timesheets/user`, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const res = await data.json();
                if (res.data) {
                    const resp = res.data as Array<Timesheet & {user_id?: string}>
                    const tempMap = new Map<string, Timesheet & {user_id?: string}>();
                    for (const obj of resp) {
                        const start_date = new Date(obj.start_date);
                        const end_date = new Date(obj.end_date);
                        
                        start_date.setMinutes(start_date.getMinutes() + start_date.getTimezoneOffset());
                        start_date.setHours(0,0,0,0);
                        end_date.setMinutes(end_date.getMinutes() + end_date.getTimezoneOffset());
                        end_date.setHours(0,0,0,0);

                        
                        tempMap.set(formatLocalDate(start_date, end_date), {...obj, start_date: start_date, end_date: end_date});
                    }
                    // @ts-ignore
                    setMapping(tempMap);
                }
                
                setLoader(false);
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }
        fetchWeeks()
    }, [refresh])
    
    const [selectedWeek, setSelectedWeek] = useState('');
    useEffect(() => {
        const arr = Array.from(mapping.keys());
        if (arr[0]) {
            setSelectedWeek(arr[0])
        }
        // setSelectedWeek(arr[0])
    }, [mapping])
    
    const [dates, setDates] = useState<Array<Date>>([]);
    const [timesheet, setTimesheet] = useState<Timesheet & {user_id? : User, _id? : string}>({
        start_date: new Date(),
        end_date : new Date(),  
        days: []
    })
    useEffect(() => {
        // alert('checking')
        const week = mapping.get(selectedWeek)
        if (week) {
            // alert(week.end_date)
            const days = getDatesInRange(week.start_date, week.end_date);
            setDates(days)
            //@ts-ignore
            const id = week._id
            setTimesheet({days: week.days, start_date: week.start_date, end_date: week.end_date, approved: week.approved, user_id: week.user_id, _id: id})
        }

    }, [selectedWeek, auth.user])
    // console.log(entries)
    const handleUpdate = async function() {
        
        // return;
        try {
            
            //@ts-ignore
            const id = timesheet._id;
            if (!id) {
                toast.error('Need timesheet!')
                return;
            }
            setLoader(true);
            const data = await fetch(`${process.env.REACT_APP_server}/timesheets/user`, {
                method: "PUT",
                headers: {
                    'Content-type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({...timesheet, total_hours_by_hour: { regular_hours: total.regular_hours,
                    team_lead_regular: total.team_lead_regular,
                    team_lead_sleep: total.team_lead_sleep,
                    night_awake_hours: total.night_awake_hours,
                    night_sleep_hours: total.night_sleep_hours,
                    sick_hours: total.sick_hours_regular,
                    sick_night_sleep: total.sick_hours_night_sleep,
                    vacation_hours: total.vacation_hours_regular,
                    vacation_night_sleep: total.vacation_hours_night_sleep }})
            })
            const res = await data.json();
            if (res.success) {
                toast.success('Timesheet updated!');
                // setTimeout(() => {
                //     setRefresh(prev => !prev)
                // }, 1200)
            }
            else {
                
                toast.error(res.error ? JSON.stringify(res.error): 'Some error');
            }
            setLoader(false);
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
        
    }

    const [total, setTotal] = useState({
        regular_hours: 0,
        night_awake_hours: 0,
        night_sleep_hours: 0,
        on_call_regular: 0,
        on_call_night_sleep: 0,
        team_lead_regular: 0,
        team_lead_night_awake: 0,
        team_lead_sleep: 0,
        team_lead_training_hours: 0,
        training_hours: 0,
        sick_hours_regular: 0,
        sick_hours_night_awake: 0,
        sick_hours_night_sleep: 0,
        vacation_hours_regular: 0,
        vacation_hours_night_awake: 0,
        vacation_hours_night_sleep: 0,
    })
    useEffect(() => {
        let total_hours = {
            regular_hours: 0,
            night_awake_hours: 0,
            night_sleep_hours: 0,
            on_call_regular: 0,
            on_call_night_sleep: 0,
            team_lead_regular: 0,
            team_lead_night_awake: 0,
            team_lead_sleep: 0,
            team_lead_training_hours: 0,
            training_hours: 0,
            sick_hours_regular: 0,
            sick_hours_night_awake: 0,
            sick_hours_night_sleep: 0,
            vacation_hours_regular: 0,
            vacation_hours_night_awake: 0,
            vacation_hours_night_sleep: 0,
        };


        timesheet?.days.forEach((day) => {
            day.shifts.forEach((shift) => {
                const hours = calculateHours24(shift.start_time, shift.end_time);
                switch (shift.hour_type) {
                    case "Regular Hours":
                    case "Training Hours":
                    case "On Call Regular":
                        total_hours.regular_hours += hours;
                        break;
                    case 'Team Lead Regular':
                    case 'Team Lead Night Awake':
                    case 'Team Lead Training':
                        total_hours.team_lead_regular += hours;
                        break;
                    case 'Team Lead Night Sleep':
                        total_hours.team_lead_sleep += hours;
                        break;
                    case 'Night Awake Hours':
                        total_hours.night_awake_hours += hours;
                        break;
                    case 'Night Sleep Hours':
                    case 'On Call Night Sleep':
                        total_hours.night_sleep_hours += hours;
                        break;
                    case 'Sick Hours (Regular)':
                    case 'Sick Hours (Night Awake)':
                        total_hours.sick_hours_regular += hours;
                        break;
                    case 'Sick Hours (Night Sleep)':
                        total_hours.sick_hours_night_sleep += hours;
                        break;
                    case 'Vacation Hours (Regular)':
                    case 'Vacation Hours (Night Awake)':
                        total_hours.vacation_hours_regular += hours;
                        break;
                    case 'Vacation Hours (Night Sleep)':
                        total_hours.vacation_hours_night_sleep += hours;
                        break;
                    default:
                        break;
                }
            })
        })
        setTotal(total_hours);
    }, [timesheet]);
    // if (true) {
    //     return <Loader />
    // }
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <AvailableWeeks weeks={Array.from(mapping.keys())} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} handleDateChange={async() => {}}></AvailableWeeks>
            </div>
            {loader ? <ComponentLoader /> : timesheet.user_id ? <><TimesheetData timesheet={timesheet} setTimesheet={setTimesheet} dates={dates} handleUpdate={async() => {}}/>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
            <Button variant="solid" color="primary" onClick={() => {
                handleUpdate()
            }} loading={loader}>Update Timesheet</Button>
            </div>
            {auth?.user?.role === 'coordinator' || auth.user?.role === 'admin' ? <div>
                <Typography>{`Regular hours ${total.regular_hours}`}</Typography>
                <Typography>{`Night Awake hours ${total.night_awake_hours}`}</Typography>
                <Typography>{`Night Sleep hours ${total.night_sleep_hours}`}</Typography>
                <Typography>{`On Call Regular hours ${total.on_call_regular}`}</Typography>
                <Typography>{`On Call Night Sleep hours ${total.on_call_night_sleep}`}</Typography>
                <Typography>{`Team Lead Regular hours ${total.team_lead_regular}`}</Typography>
                <Typography>{`Team Lead Night Awake hours ${total.team_lead_night_awake}`}</Typography>
                <Typography>{`Team Lead Night Sleep hours ${total.team_lead_sleep}`}</Typography>
                <Typography>{`Team Lead Training hours ${total.team_lead_training_hours}`}</Typography>
                <Typography>{`Training hours ${total.training_hours}`}</Typography>
                <Typography>{`Sick Hours (Regular) ${total.sick_hours_regular}`}</Typography>
                <Typography>{`Sick Hours (Night Awake) ${total.sick_hours_night_awake}`}</Typography>
                <Typography>{`Sick Hours (Night Sleep) ${total.sick_hours_night_sleep}`}</Typography>
                <Typography>{`Vacation Hours (Regular) ${total.vacation_hours_regular}`}</Typography>
                <Typography>{`Vacation Hours (Night Awake) ${total.vacation_hours_night_awake}`}</Typography>
                <Typography>{`Vacation Hours (Night Sleep) ${total.vacation_hours_night_sleep}`}</Typography>

            </div> : <></>}
            </> : <>No timesheet added</>}
            {}
            
        </div>
    )
}


export default PastSheets