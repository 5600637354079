import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

import MenuIcon from '@mui/icons-material/Menu';

import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// custom
import Menu from './Menu';
import Layout from './Layout';
import { Box, IconButton, Typography } from '@mui/joy';
import { useAuth } from '../providers/AuthContext';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useNavigation } from 'react-router-dom';
function Navbar({ children }: { children?: React.ReactNode }) {
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <Layout.Header sx={{

    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1.5,
        }}
      >

        <IconButton
          size="lg"
          variant="soft"
          onClick={() => {
            navigate("/")
          }}
          sx={{ display: { sm: 'inline-flex' } }}
        >
          <img src={`${process.env.PUBLIC_URL}/ssg3.png`} width={'40'}></img>
        </IconButton>
        <Typography component="h1" fontWeight="xl">
          Support Services Group / SSG
        </Typography>
      </Box>
      {/* {children} */}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>

        {(auth.user?.role === 'admin' || auth.user?.role === 'coordinator') && <IconButton
          variant='outlined'
          size='lg'
          onClick={() => {
            navigate('/timesheet')
          }}
        >
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <GridViewRoundedIcon fontSize='medium' />
            <Typography ml={2} fontSize={'medium'}>Timesheet</Typography>
          </div>
        </IconButton>}

        <Menu
          id="app-selector"
          control={
            <IconButton
              variant='outlined'
              size='lg'

            >
              <AccountCircleIcon />
            </IconButton>
          }
          menus={[
            {
              label: 'Mileages',
              onClick: () => {
                navigate(auth.user?.role === 'employee' ? '/mileage' : '/mileages')
              },
              style: {
                // backgroundColor: 'black',
                marginTop: 15
              }
            },
            {
              label: 'Logout',
              onClick: () => {
                localStorage.removeItem('token');
                window.location.reload();
              },
              style: {
                // backgroundColor: 'black',
                marginTop: 15
              }
            }

          ]}
        />
        {/* <ColorSchemeToggle /> */}
      </Box>
    </Layout.Header>
  )
}
export default Navbar;