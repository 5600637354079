import { Table, Input, Select, Sheet, Button } from "@mui/joy";
import { ShiftType, User } from "../../types";
import { hour_types } from "../../utils/helpers";
import LaunchIcon from '@mui/icons-material/Launch';
import { FC } from "react";
import { Link } from "react-router-dom";


interface EntryProps {
    entries: Array<User>
}
const PendingEntries: FC<EntryProps> = function ({ entries }) {
    return (
        <Sheet sx={{ height: '60dvh', overflow: 'auto' }}>
            <Table aria-label="table 'outlined's" variant={'soft'} sx={{
                overflowX: 'scroll',
                width: '100%',
                // paddingLeft: 1
                mt: 1
            }} stickyHeader={true}>
                <thead >
                    <tr style={{ backgroundColor: 'black', color: 'white' }}>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>First Name</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Last Name</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Location</th>
                        <th style={{ backgroundColor: 'black', color: 'white', width: 200 }}>Username</th>
                        
                    </tr>

                </thead>

                <tbody >

                    {entries.map((entry) => {
                        return (
                            <tr>
                                <td>{entry.first_name}</td>
                                <td>{entry.last_name}</td>
                                <td>{entry.location}</td>
                                <td>{entry.username}</td>
                            </tr>
                        )
                    })}



                </tbody>

            </Table>
        </Sheet>
    )
}


export default PendingEntries