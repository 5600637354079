import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '../../ui/Loader';
import { Autocomplete, Button, CircularProgress, Input, Link, Option, Select, Typography } from '@mui/joy';
import debounce from 'lodash.debounce';
import { useAuth } from '../../providers/AuthContext';
import toast from 'react-hot-toast';
import { Entries, MileageEntries } from '../../types';

interface EntryProps {
    // Define component props here
    entry: Entries
    member: string;
    refreshEntries: () => void;
}
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octobor', 'November', 'December']
const Entry: FC<EntryProps> = ({ entry, member, refreshEntries }) => {
    const auth = useAuth()

    const date = entry.date.split("T")[0]
    const dateCombs = date.split("-")

    const [mileage, setMileage] = useState({
        member: member,
        day: dateCombs[2],
        month: months[(parseInt(dateCombs[1]) - 1) % 12],
        year: dateCombs[0],
        from: entry.from,
        fromId: 'diff',
        toId: 'diff',
        to: entry.to,
        km_auto: entry.km_auto,
        km_manual: entry.km_manual,
        comment: entry.comment || '',
        file: null as File | null,
        bridge: entry.bridge.toString(),
        parking: entry.parking.toString()
    })
    const [attachement, setAttachement] = useState<File>()
    const [distanceLoading, setDistanceLoading] = useState(false)
    const [fromLoading, setFromLoading] = useState(false)
    const [toLoading, setToLoading] = useState(false)
    const [fromSuggestions, setFromSuggestions] = useState<Array<{ place_id: string, description: string }>>([{ place_id: '', description: mileage.from }]);
    const [toSuggestions, setToSuggestions] = useState<Array<{ place_id: string, description: string }>>([{ place_id: '', description: mileage.to }])
    const fileRef = useRef<HTMLInputElement>(null)
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setMileage((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    useEffect(() => {

        async function calculateDistance() {
            setDistanceLoading(true)
            try {
                const response = await fetch(`${process.env.REACT_APP_server}/mileage/distance`, {
                    method: 'POST',
                    body: JSON.stringify({ from: mileage.fromId, to: mileage.toId }),
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const data = await response.json();

                setDistanceLoading(false)
                if (!data.error) {
                    setMileage((prev) => {
                        return {
                            ...prev,
                            km_auto: Math.round(data.distance / 1000),
                            km_manual: Math.round(data.distance / 1000)
                        }
                    })
                }
            } catch (error) {
                setDistanceLoading(false)
            }

        }
        if (mileage.fromId.length !== 0 && mileage.toId.length !== 0) {

            // calculateDistance()
        }
    }, [mileage.fromId, mileage.toId])
    const fetchFromOptions = async (value: string) => {
        // try {
        //     const response = await fetch(`${process.env.REACT_APP_server}/mileage/suggestion?keyword=${value}`, {
        //         headers: {
        //             'Content-type': 'application/json',
        //             'Authorization': 'Bearer ' + localStorage.getItem('token')
        //         }
        //     })
        //     const data = await response.json()
        //     setFromLoading(false)
        //     if (!data.error) {
        //         setFromSuggestions(data)
        //     }
        // } catch (error) {
        //     setFromLoading(false)
        // }
    }
    const fetchToOptions = async (value: string) => {
        // const response = await fetch(`${process.env.REACT_APP_server}/mileage/suggestion?keyword=${value}`, {
        //     headers: {
        //         'Content-type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('token')
        //     }
        // })
        // const data = await response.json()
        // setToLoading(false)
        // if (!data.error) {
        //     setToSuggestions(data)
        // }
    }
    console.log(fromSuggestions)

    const debouncedFetchFrom = useCallback(debounce(fetchFromOptions, 3000), [])
    const debouncedFetchTo = useCallback(debounce(fetchToOptions, 3000), [])
    const [deleteLoading, setDeleteLoading] = useState(false)
    async function handleDelete() {
        try {
            setDeleteLoading(true)
            const response = await fetch(`${process.env.REACT_APP_server}/mileage/myentry/${entry._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            const data = await response.json()
            setDeleteLoading(false)
            if (data.success) {
                toast.success("Entry deleted successfully!");
                refreshEntries()
                return;
            }
            toast.error(data.error)
        } catch (error) {
            setDeleteLoading(false)
            console.log(error)
        }
    }
    return (
        <>
            {distanceLoading && <div style={{

                display: 'flex',


                position: 'absolute',
                left: '50%',
            }}>

                <Loader />

            </div>}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                marginTop: 20,
                width: 'fit-content',
                maxWidth: '85%',
                marginLeft: 'auto',
                marginRight: 'auto',
                overflowX: 'scroll',
                opacity: distanceLoading ? 0.25 : 1,

            }} >

                <div>
                    <div style={{ display: 'flex', gap: 20, flexDirection: window.innerWidth < 600 ? 'column' : 'row' }}>
                        <div>
                            <Typography fontSize={'lg'} fontWeight={'bold'}>Date:</Typography>
                            <div style={{ display: 'flex', gap: 4 }}>
                                <Input type='number' name='day' value={mileage.day} placeholder='DD' ></Input>
                                <Select value={mileage.month} onChange={(e, v) => {
                                    // if (v) {
                                    //     setMileage((prev) => {
                                    //         return {
                                    //             ...prev,
                                    //             month: v
                                    //         }
                                    //     })
                                    // }

                                }}>
                                    {months.map((month) => <Option value={month} key={month}>{month}</Option>)}
                                </Select>
                                <Input type='number' name='year' value={mileage.year} placeholder='YYYY' ></Input>
                            </div>
                        </div>
                        <div>
                            <Typography fontSize={'lg'} fontWeight={'bold'}>Member:</Typography>
                            <div style={{ display: 'flex', gap: 4 }}>
                                <Input placeholder='Member Name' id='member' name='member' value={mileage.member} />
                            </div>
                        </div>

                    </div>

                </div>

                <div >
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Locations:</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: 5 }}>
                        <div>
                            <label htmlFor='from'>From:</label>
                            <Input value={fromSuggestions[0].description} placeholder='From' id='from' name='from' />
                        </div>
                        <div>
                            <label htmlFor='to'>To:</label>
                            <Input value={toSuggestions[0].description} placeholder='To' id='to' name='To' />

                        </div>

                    </div>
                </div>

                <div style={{ display: 'flex', gap: 20, flexDirection: window.innerWidth < 600 ? 'column' : 'row' }}>
                    <div>
                        <Typography fontSize={'lg'} fontWeight={'bold'}>Mileage Count:</Typography>
                        <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                            {/* <Input mile /> */}
                            <div>
                                <label htmlFor='km_auto'>Kilometers (calculated): </label>
                                <Input type='number' value={mileage.km_auto} name='km_auto' id='km_auto'></Input>
                            </div>
                            <div>
                                <label htmlFor='km_manual'>Kilometers (manual): </label>
                                <Input type='number' value={mileage.km_manual} name='km_manual' id='km_manual'></Input>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Typography fontSize={'lg'} fontWeight={'bold'}>Charges:</Typography>
                        <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                            <div>
                                <label htmlFor='bridge'>Bridge: </label>
                                <Input type='number' value={mileage.bridge} name='bridge' id='bridge' ></Input>
                            </div>
                            <div>
                                <label htmlFor='parking'>Parking: </label>
                                <Input type='number' value={mileage.parking} name='parking' id='parking'></Input>
                            </div>
                        </div>

                    </div>
                </div>
                {mileage.km_auto != mileage.km_manual && <div style={{ width: '100%' }}>
                    <Typography fontSize={'lg'} fontWeight={'bold'}>Comment:</Typography>
                    <div style={{ display: 'flex', gap: 4, marginTop: 5, width: '100%' }}>
                        {/* <Input mile /> */}
                        <Input type='text' value={mileage.comment} name='comment' id='comment' placeholder='' fullWidth ></Input>
                    </div>
                </div>}

                <div>
                    {entry.attachments && <Link href={entry.attachments} target='_blank'>Attachment</Link>}
                    {/* <Typography fontSize={'lg'} fontWeight={'bold'}>Attachments(Optional):</Typography>
                    <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                        <Input disabled type='file' onChange={(e) => {
                            if (e.target.files?.[0]) {
                                const size = e.target.files[0].size;
                                alert(size)
                                if (size > 4 * 1024 * 1024) {
                                    alert('File size should be less than 4mb')
                                    e.target.value = null as any
                                    return;
                                }
                            }
                            setAttachement(e.target.files?.[0])
                        }} ref={fileRef}></Input>
                    </div> */}
                </div>
                <div style={{ marginBottom: 10, display: 'flex', gap: 10 }}>


                    <Button color='danger' onClick={handleDelete} loading={deleteLoading}>Delete Mileage</Button>
                </div>
            </div></>
    );
};

export default Entry;