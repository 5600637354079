import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import {Button, Option, Select, Sheet, Table} from "@mui/joy";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {ComponentLoader} from "../../ui/Loader";

export const Holidays = function() {
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false);
    const [holidays, setHolidays] = useState<Array<{date: string}>>([])

    const [loader, setLoader] = useState(false);
    const [addLoader, setAddLoader] = useState(false)
    useEffect(() => {
        setLoader(true)
        fetch(`${process.env.REACT_APP_server}/holidays`, {
            headers: {
                "Authorization" : "Bearer " + localStorage.getItem("token")
            }

        }).then((data) => data.json()).then((data) => {

            if (data.success) {
                setHolidays(data.data)
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false);
            console.log(err);
        })
    }, [refresh])

    const [date, setDate] = useState<any>();

    function addHoliday(e : any) {
        e.preventDefault()
        const obj = new Date(date)
        setAddLoader(true)
        obj.setMinutes(obj.getMinutes() + obj.getTimezoneOffset())
        obj.setHours(0,0,0,0)
        fetch(`${process.env.REACT_APP_server}/holidays`, {
            method: "POST",
            headers: {
                "Authorization" : "Bearer " + localStorage.getItem("token"),
                "Content-type" : "application/json"
            },
            body: JSON.stringify({
                date: obj.toDateString()
            })
        }).then((data) => data.json()).then((res) => {
            if (res.success) {
                toast.success("Holiday added");
                setRefresh(prev => !prev)
            }
            else {
                toast.error(res.error)
            }
            setAddLoader(false)
        }).catch((err) => {
            setAddLoader(false)
            console.log(err)
        })
    }
    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 25}}>
            <form style={{
                width: '70dvw',
                overflowX: 'scroll'
            }} onSubmit={addHoliday}> <FormLabel>Select Date</FormLabel>
                <Input type={'date'} required onChange={(e) => {

                    setDate(e.target.value)
                }}></Input>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 10
                }}>
                <Button variant={'solid'} type={'submit'} loading={addLoader} >Add Holiday</Button>
                </div>

            </form>
                {loader ? <ComponentLoader /> :  <div style={{ cursor: 'pointer' }}>
                    <Sheet sx={{ height: '60dvh', overflow: 'auto' }}>
                        <Table aria-label="table 'outlined's" variant={'soft'} sx={{
                            overflowX: 'scroll',
                            width: '100%',
                            mt: 1
                            // paddingLeft: 1
                        }} stickyHeader={true}>
                            <thead >
                            <tr style={{ backgroundColor: 'black', color: 'white' }}>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 300 }}>Date</th>
                                <th style={{ backgroundColor: 'black', color: 'white', width: 50 }}>Actions</th>
                            </tr>

                            </thead>

                            <tbody >
                            {holidays.map((holiday) => {
                                return <tr>
                                    <td>
                                        {holiday.date}
                                    </td>

                                    <td>
                                        <Button color={'danger'}
                                                onClick={() => {
                                                    fetch(`${process.env.REACT_APP_server}/holidays`, {
                                                        method: 'DELETE',
                                                        headers: {
                                                            "Authorization" : "Bearer " + localStorage.getItem("token"),
                                                            "Content-type" : "application/json"
                                                        },
                                                        body: JSON.stringify({date : holiday.date})
                                                    }).then((data)=> {
                                                        toast.success("Deleted!")
                                                        setRefresh(prev => !prev)
                                                    }).catch((err) => {
                                                        console.log(err)
                                                    })
                                                }}>Delete</Button>
                                    </td>
                                </tr>
                            })}
                            </tbody>

                        </Table>
                    </Sheet>
                </div>}

            </div>
        </div>
    )
}