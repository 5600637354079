import { useEffect } from "react"
import SubmitTimesheet from "../modules/timesheet/SubmitTimesheet"
import { useAuth } from "../providers/AuthContext"
import WeeklyShifts from "../modules/coordinator/WeeklySheets"
import JoySignInSideTemplate from "../modules/auth/Login"
import Navbar from "../ui/Navbar"
import Users from "../modules/admin/Users"

function Home() {
    const auth = useAuth()
    
    
    if (auth.user) {
        if (auth.user.role === 'coordinator') {
            return (
                <div className="home-page">
                    <WeeklyShifts />
                </div>
            )
        }
        else if(auth.user.role === 'admin') {
            return (
                <div style={{marginLeft: 25, marginRight: 25}}>
                    <Navbar />
                    <Users />
                </div>
            )
        }
        return (
            <div className="home-page">
                <SubmitTimesheet></SubmitTimesheet>
            </div>
        )

    }

    return <JoySignInSideTemplate />

    // return (

    //     <div className="home-page">
    //          <SubmitTimesheet />
    //     </div>
    // )
}


export default Home