import { useEffect, useState } from "react"
import { useAuth } from "../../providers/AuthContext"
import { formatLocalDate } from "../../utils/helpers";
import AvailableWeeks from "../timesheet/AvailableWeeks";
import { ComponentLoader, Loader } from "../../ui/Loader";
import Entries from "./Entries";
import { User } from "../../types";
import Navbar from "../../ui/Navbar";
import { Button, Tab, TabList, Tabs } from "@mui/joy";
import PendingEntries from "./PendingEntries";
import moment from "moment-timezone";
import { useNavigate, useNavigation, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

const WeeklyShifts = function () {
    const auth = useAuth();
    const [mapping, setMapping] = useState(new Map<string, { start_date: string, end_date: string }>())
    const [loader, setLoader] = useState(true);
    
    const [search, setSearch] = useSearchParams()
    const [weekLoader, setWeekLoader] = useState(false);
    useEffect(() => {
        async function fetchWeeks() {
            try {
                setLoader(true);
                // alert('true')
                const data = await fetch(`${process.env.REACT_APP_server}/timesheets`, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                const res = await data.json();
                if (res.data) {
                    const resp = res.data as Array<{ start_date: string, end_date: string }>
                    const tempMap = new Map<string, { start_date: string, end_date: string }>();
                    for (const obj of resp) {
                        const start_date = new Date(obj.start_date);
                        const end_date = new Date(obj.end_date);
                        
                        start_date.setMinutes(start_date.getMinutes() + start_date.getTimezoneOffset());
                        start_date.setHours(0, 0, 0, 0);
                        end_date.setMinutes(end_date.getMinutes() + end_date.getTimezoneOffset());
                        end_date.setHours(0, 0, 0, 0);

                        
                        tempMap.set(formatLocalDate(start_date, end_date), { start_date: obj.start_date.split('T')[0], end_date: obj.end_date.split('T')[0] });
                    }
                    setMapping(tempMap);
                }
                setLoader(false);
                // alert('false')
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }
        fetchWeeks()
    }, [])
    const [selectedWeek, setSelectedWeek] = useState('');
    useEffect(() => {
        const arr = Array.from(mapping.keys());
        if (arr[0]) {
            setSelectedWeek(arr[parseInt(search.get("idx") || "0")%arr.length])
        }
        // setSelectedWeek(arr[0])
    }, [mapping])


    const [entries, setEntries] = useState<Array<{ _id: string, user_id: User, approved: boolean, createdAt: Date, daysWorked: number }>>([])
    const [pending, setPending] = useState< Array<User>>([]);
    function DownloadCsv(approved? : boolean) {
        const day = mapping.get(selectedWeek);
        fetch(`${process.env.REACT_APP_server}/timesheets/file?start_date=${day?.start_date}&end_date=${day?.end_date}${approved ? '&approved=true': ''}`, {
            method: "GET",
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((data) => data.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            let start = moment(day?.start_date).format("MM-DD-YYYY")
            let end = moment(day?.end_date).format("MM-DD-YYYY")
            a.download = approved ? `Approved Sheets ${start + " to " + end}` : `All Sheets ${start + " to " + end}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        async function fetchEntries() {
            try {
                const date = mapping.get(selectedWeek);


                if (date && auth.user) {
                    setWeekLoader(true)
                    const data = await fetch(`${process.env.REACT_APP_server}/timesheets/weekly?start_date=${date.start_date}&end_date=${date.end_date}`, {
                        headers: {
                            'Content-type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    const res = await data.json();
                    if (res.success && res.data) {
                       
                        setEntries(res.data.timesheets ? res.data.timesheets : []);
                        setPending(res.data.pending ? res.data.pending : []);
                        // alert(res.data.timesheets.length)
                    }
                    setWeekLoader(false);
                }
            } catch (error) {
                setWeekLoader(false)
            }
        }
        fetchEntries();
    }, [selectedWeek, auth.user])


    const navigate = useNavigate();
    useEffect(() => {
        const weeks = Array.from(mapping.keys())
        const idx = weeks.indexOf(selectedWeek);
        if (idx != -1) {
            navigate("?idx=" + idx, {replace: true})
        }
    }, [selectedWeek])

    const [index, setIndex] = useState(0);
    
    if (loader) {
        return <div className="weekly-shifts" style={{
            marginLeft: 25,
            marginRight: 25
        }}>
              <Navbar />
              <ComponentLoader />
        </div>
    }
    if (selectedWeek == '') {
        return (
            <div className="weekly-shifts" style={{
                marginLeft: 25,
                marginRight: 25
            }}>
                  <Navbar />
                  <div style={{display: 'flex', justifyContent: 'center'}}>No sheet present</div>
            </div>
        )
    }
    return (
        <div className="weekly-shifts" style={{
            marginLeft: 25,
            marginRight: 25
        }}>
            <Navbar />
            <div className="weeks" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <AvailableWeeks weeks={Array.from(mapping.keys())} handleDateChange={() => { }} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
            </div>
           {weekLoader ? <ComponentLoader />:  <>
            <Tabs
                aria-label="Soft tabs"
                value={index}
                onChange={(event, value) => setIndex(value as number)}

                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <TabList variant="plain" tabFlex={1}>
                    <Tab
                        variant={index === 0 ? 'solid' : 'plain'}
                        color={'neutral'}
                    >
                        Employees who submitted
                    </Tab>
                    <Tab
                        variant={index === 1 ? 'solid' : 'plain'}
                        color={'neutral'}

                    >
                        Employees who haven't submitted
                    </Tab>
                </TabList>
            </Tabs>
            {index == 0 && <Entries entries={entries} />}
            {index == 1 && <PendingEntries entries={pending} /> }
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 25 }} >
                <Button onClick={() => {
                    DownloadCsv(true)
                }}>Download Approved Sheets</Button>
                <Button onClick={() => {
                    DownloadCsv()
                }}>Download All Sheets</Button>
            </div>
            </>}
        </div>
    )
}


export default WeeklyShifts