import { Modal, ModalClose, ModalDialog, ModalOverflow, Typography } from '@mui/joy';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MileageEntries } from '../../types';
import Entries from './Entries';

interface MilageEntriesProps {
    // Define component props here
    isOpen: boolean
    onOpenChange: () => void;
    id: string;
    month: string;
    user: string
}

const MilageEntries: FC<MilageEntriesProps> = ({ isOpen, onOpenChange, id, month, user }) => {
    const [entries, setEntries] = useState<{ _id: string, member: string, approved: boolean, entries: MileageEntries[] }[]>([])
    const [loading, setLoading] = useState(true)


    return (
        <Modal open={isOpen} onClose={onOpenChange}>
            <ModalOverflow>
                <ModalDialog
                    color="neutral"
                    size="lg"
                    variant="outlined"
                >
                    <ModalClose />
                    <Typography>Mileage Entries of {month} for {user}</Typography>
                    <Entries id={id} />
                </ModalDialog>
            </ModalOverflow>
        </Modal>
    );
};

export default MilageEntries;
