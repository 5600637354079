import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { useEffect, useState } from "react";
import Staff from "./Staff";
import { useSearchParams } from "react-router-dom";
import Members from "./Members";
import {Holidays} from "./Holidays";

const Users = function () {

    const [index, setIndex] = useState(0);
    return (
        <div>
            <Tabs
                aria-label="Soft tabs"
                value={index}
                onChange={(event, value) => setIndex(value as number)}
            
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <TabList variant="plain" tabFlex={1}>
                    <Tab
                        variant={index === 0 ? 'solid' : 'plain'}
                        color={'neutral'}
                        sx={{fontSize: 'small'}}
                    >
                        {window.innerWidth < 500 ? "Emp" : "Employee"}
                    </Tab>
                    <Tab
                        variant={index === 1 ? 'solid' : 'plain'}
                        color={'neutral'}
                        sx={{fontSize: 'small'}}
                    >
                        {window.innerWidth < 500 ? "Coord" : "Coordinator/Payroll"}
                    </Tab>
                    <Tab
                        variant={index === 2 ? 'solid' : 'plain'}
                        color={'neutral'}
                        sx={{fontSize: 'small'}}
                    >
                        Members
                    </Tab>
                    <Tab variant={index === 3 ? 'solid' : 'plain'}
                         color={'neutral'} sx={{fontSize: 'small'}}>
                        Holidays
                    </Tab>
                </TabList>
            </Tabs>
            {index !== 3 && <Staff role={`${index === 0 ? 'employee' : index === 1 ? 'coordinator' : 'member'}`}/>}
            {index === 3 && <Holidays />}

            
        </div>
    )
}

export default Users;