import { FC } from 'react'
import DateShifts from './DateShifts'
import { Timesheet } from '../../types'


interface DateProps {
    dates : Array<Date>
    timesheet: Timesheet
    setTimesheet : React.Dispatch<React.SetStateAction<Timesheet>>
}

//@ts-ignore
const Dates : FC<DateProps> = ({dates, timesheet, setTimesheet}) => {
    return (
        <div style={{width: '100%'}}>
        {dates.map((date, i) => {
            return <div key={i} style={{marginTop: 30, paddingBottom: 30, borderBottom: '1px solid #E2DFD2'}}><DateShifts date={date} timesheet={timesheet} setTimesheet={setTimesheet}/></div>
        })}
        </div>
    )
}

export default Dates