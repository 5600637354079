import moment from "moment-timezone";

function getLast8Weeks(): { start_date: Date; end_date: Date }[] {
    const currentDate = new Date();

    // const mdate = moment(currentDate).format('YYYY-MM-DD');
    // alert(mdate);
    currentDate.setHours(0, 0, 0, 0);
    const currentDay = currentDate.getDay(); // 0 is Sunday, 1 is Monday, and so on

    // Calculate the difference between the current day and Saturday
    const daysUntilSaturday = (6 - currentDay + 7) % 7;

    // Set the current date to the Saturday of the current week
    const saturdayOfCurrentWeek = new Date(currentDate);
    saturdayOfCurrentWeek.setDate(currentDate.getDate() + daysUntilSaturday);

    // Initialize an array to store the start and end dates of each week
    const weeks = [];

    const formatDate = (date: Date): string => date.toISOString().split("T")[0]; // Get the date part without the timestamp

    for (let i = 0; i < 8; i++) {
        const start_date = new Date(saturdayOfCurrentWeek);
        start_date.setDate(saturdayOfCurrentWeek.getDate() - 7 * i); // Go back i weeks

        const end_date = new Date(start_date);
        end_date.setDate(end_date.getDate() - 1); // Set the end date to the current week's Friday

        const nextSaturday = new Date(end_date);
        nextSaturday.setDate(nextSaturday.getDate() - 6); // Move to the next week's Saturday

        weeks.push({
            start_date: nextSaturday,
            end_date: end_date
        });
    }

    return weeks;
}
// Example usage
const last8Weeks = getLast8Weeks();

const formatDate = (date: Date): string => date.toISOString().split("T")[0];

export const formatLocalDate = (start_date?: Date, end_date?: Date) => {
    return start_date?.toLocaleDateString() + "-" + end_date?.toLocaleDateString()
}


export const formattedDates = () => {
    const dates = getLast8Weeks();
    const mapping = new Map<string, { start_date: Date, end_date: Date }>()
    for (const week of dates) {
        const dateFormat = formatLocalDate(week.start_date, week.end_date);
        mapping.set(dateFormat, { start_date: week.start_date, end_date: week.end_date });
    }
    return mapping;
}



export const getDatesInRange = (start_date?: Date, end_date?: Date) => {
    if (!start_date || !end_date) {
        return []
    }
    const currentDate = new Date(start_date);
    const endDate = end_date.toISOString();
    let results: Array<Date> = []
    while (currentDate.toISOString() <= endDate) {
        const newDate = new Date(currentDate);
        results.push(newDate)
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return results;
}


export const hour_types = [
    "Regular Hours",
    "Night Awake Hours",
    "Night Sleep Hours",
    "On Call Regular",
    "On Call Night Sleep",
    "Team Lead Regular",
    "Team Lead Night Awake",
    "Team Lead Night Sleep",
    "Team Lead Training",
    "Training Hours",
    "Sick Hours (Regular)",
    "Sick Hours (Night Awake)",
    "Sick Hours (Night Sleep)",
    "Vacation Hours (Regular)",
    "Vacation Hours (Night Awake)",
    "Vacation Hours (Night Sleep)",
] as const;

export const calculateHours = (startTime?: string, endTime?: string) => {
    if (!startTime || !endTime) return 0;
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);

    let roundedVal = Math.round((end.getTime() - start.getTime()) / 3600000 * 100) / 100;

    return endTime === '23:59' ? roundedVal += 0.02 : roundedVal
};

export const get24HourTime = (time: string) => {
    let newTime = "";
    const timeArray = time.split(":");
    if (timeArray[2] === 'AM') {
        if (timeArray[0] === "12") {
            newTime += "00:" + timeArray[1]
        }
        else {
            newTime += timeArray[0] + ":" + timeArray[1]
        }
    }
    else {
        let num = parseInt(timeArray[0])

        if (num != 12) num += 12

        newTime += num + ":" + timeArray[1]
    }
    return newTime
}
export const calculateHours24 = (startTime?: string, endTime?: string) => {
    if (!startTime || !endTime) return 0;
    const start_time = get24HourTime(startTime)
    const end_time = get24HourTime(endTime);
    // alert(startTime)
    // alert(end_time)


    return calculateHours(start_time, end_time);

}



export const Hours = ["12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"]
export const Minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "59"]



export const getLast12Months = function () {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonth = new Date()

    let result: string[] = []
    for (let i = 0; i < 12; i++) {
        const month = (currentMonth.getMonth() - i + 12) % 12;
        let val = 0;
        if (Math.abs(i) > currentMonth.getMonth()) val = 1;
        const year = currentMonth.getFullYear() - val;

        result.push(`${months[month]} - ${year}`)
    }
    return result
}
export default { getLast8Weeks, formatDate }


